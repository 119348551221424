import { Box, EmptyState, Headline, Palette, Stack, Text } from "@secuis/ccp-react-components";
import i18next from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TileContainer } from "src/components/Insights/common/InsightTile";
import { TileLoader } from "src/components/Insights/TileLoader/TileLoader";
import { IMultiListItem } from "src/components/shared/CheckboxList/CheckboxListItem.types";
import { HorizontalLine } from "src/components/shared/HorizontalLine";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import { UserPreference } from "src/models/UserModel";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";
import { useReportCategoryItems } from "src/store/filter/FilterHooks";
import TrackingActions from "src/store/tracking/TrackingActions";
import { useSetUserPreference, useUserInfo } from "src/store/user/UserHooks";

import { TrendingEventsChart } from "../../shared/components/TrendingEventsChart";
import { AdditionalInfoTooltip } from "./AdditionalInfoTooltip";
import { CustomizeModal } from "./CustomizeModal";
import { useTrendingEvents } from "./hooks";
import { ResetModal } from "./ResetModal";
import { ButtonTextStyled, EmptyStateContainer, HeaderContainerStyled, StyledStack } from "./TrendingEvents.styles";
import { CustomizedCategories, CustomizedCategory } from "./TrendingEvents.types";

// 1st is month, 2nd and further are data lines
const MIN_LINES_TO_DISPLAY = 2;
const MONTHS_BACK = 6;

export const TrendingEvents = () => {
    const { t } = useTranslation();
    const { isMobileLayout, isFilterActive } = useInsightsPageContext();
    const { reportCategories } = useReportCategoryItems();
    const [selectedCategories, setSelectedCategories] = useState<CustomizedCategories>(null);
    const { isLoading, events } = useTrendingEvents(selectedCategories, MONTHS_BACK);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isResetToDefaultModalOpen, setIsResetToDefaultModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { info: userInfo } = useUserInfo();
    const setUserPreference = useSetUserPreference();

    const renderChart = useCallback(() => {
        if (!events?.length || Object.values(events[0]).length < MIN_LINES_TO_DISPLAY) {
            return (
                <Stack direction="column">
                    <EmptyStateContainer direction="column" justifyContent="center" alignItems="center" flex={1} pv="XL">
                        <EmptyState icon="GraphIndicator" title={t("insights.trendingEvents.emptyState.title")} />
                        <Stack alignItems="center">
                            <Text>{t("insights.trendingEvents.emptyState.subtitle")}</Text>
                        </Stack>
                    </EmptyStateContainer>
                    <StyledStack mb="S" mt="S" mr="S">
                        <Text color="disabledText" bold>
                            {t("insights.trendingEvents.emptyState.noReports")}
                        </Text>
                    </StyledStack>
                </Stack>
            );
        }

        return <TrendingEventsChart events={events} selectedCategories={selectedCategories} />;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, t]);

    const handleOpenClose = () => {
        if (!isModalOpen) dispatch(TrackingActions.trackEvent(MixpanelEvent.CustomizeTop5IncidentsOpen));
        setIsModalOpen((prev) => !prev);
    };

    const handleSave = (selected: IMultiListItem[]) => {
        const level1CategoriesDeep: IMultiListItem[] = selected.filter((x) => !x.parent);

        const level2CategoriesDeep: IMultiListItem[] = selected.filter((x) => x.children.length && x.parent);

        const level3CategoriesDeep: IMultiListItem[] = selected.filter((x) => !x.children.length && x.parent);

        const level1Categories: CustomizedCategory[] = level1CategoriesDeep.map((c) => ({ value: c?.value }));
        const level2Categories: CustomizedCategory[] = level2CategoriesDeep.map((c) => ({
            value: c?.value,
            parent: c?.parent.value,
        }));
        const level3Categories: CustomizedCategory[] = level3CategoriesDeep.map((c) => ({
            value: c?.value,
            parent: c?.parent.value,
            root: c?.parent.parent.value,
        }));

        dispatch(TrackingActions.trackEvent(MixpanelEvent.CustomizeTop5IncidentsApply));
        setUserPreference(
            UserPreference.Top5IncidentsCategories,
            JSON.stringify({
                level1Categories,
                level2Categories,
                level3Categories,
            }),
        );

        setSelectedCategories({
            level1Categories: level1CategoriesDeep,
            level2Categories: level2CategoriesDeep,
            level3Categories: level3CategoriesDeep,
        } as CustomizedCategories);
        setIsModalOpen((prev) => !prev);
    };

    const handleResetModalOpenClose = () => {
        setIsResetToDefaultModalOpen((prev) => !prev);
    };

    const handleReset = () => {
        dispatch(TrackingActions.trackEvent(MixpanelEvent.CustomizeTop5IncidentsReset));
        setUserPreference(UserPreference.Top5IncidentsCategories, null);
        setSelectedCategories(null);
        setIsResetToDefaultModalOpen((prev) => !prev);
    };

    useEffect(() => {
        if (userInfo?.preferences?.top5IncidentsCategories && !!reportCategories.length) {
            const loadedCategories = JSON.parse(userInfo.preferences.top5IncidentsCategories);

            const level1Categories = loadedCategories?.level1Categories
                ?.map((category) => reportCategories.find((c) => c?.value === category?.value) || null)
                .filter(Boolean);

            const level2Categories = loadedCategories?.level2Categories
                ?.map((category) => reportCategories.find((c) => c?.value === category?.parent)?.children?.find((c) => c?.value === category?.value) || null)
                .filter(Boolean);

            const level3Categories = loadedCategories?.level3Categories
                ?.map(
                    (category) =>
                        reportCategories
                            .find((c) => c?.value === category?.root)
                            ?.children?.find((c) => c?.value === category?.parent)
                            ?.children?.find((c) => c?.value === category?.value) || null,
                )
                .filter(Boolean);

            setSelectedCategories({ level1Categories, level2Categories, level3Categories } as CustomizedCategories);
        }
    }, [reportCategories, userInfo]);

    const isResetVisible = useMemo(() => {
        return (
            !!selectedCategories &&
            (!!selectedCategories.level1Categories?.length || !!selectedCategories.level2Categories?.length || !!selectedCategories.level3Categories?.length)
        );
    }, [selectedCategories]);

    return (
        <Stack direction="column">
            {isLoading ? (
                <TileLoader height="400px" />
            ) : (
                <Stack direction="column" gap={["M", "S", "S"]} data-testid="trending-events-container">
                    <TileContainer>
                        <HeaderContainerStyled isFilterActive={isFilterActive}>
                            <Stack alignItems="center" flexWrap="wrap">
                                <Headline bold={true} data-testid="insights-overview-title">
                                    {t("insights.trendingEvents.title")}
                                </Headline>
                                <AdditionalInfoTooltip />
                            </Stack>
                            <Stack gap={"M"} direction={isMobileLayout ? "row-reverse" : "row"} justifyContent={isMobileLayout ? "flex-end" : null}>
                                {isResetVisible && (
                                    <>
                                        <ButtonTextStyled icon="Reload" onClick={handleResetModalOpenClose}>{`${i18next.t(
                                            "filters.resetDefault",
                                        )}`}</ButtonTextStyled>
                                        <ResetModal
                                            isModalOpen={isResetToDefaultModalOpen}
                                            handleReset={handleReset}
                                            handleOpenClose={handleResetModalOpenClose}
                                        />
                                    </>
                                )}
                                <ButtonTextStyled icon="Edit" onClick={handleOpenClose} color="accent">{`${i18next.t("filters.customize")}`}</ButtonTextStyled>
                                <CustomizeModal
                                    isModalOpen={isModalOpen}
                                    reportCategories={reportCategories}
                                    preselectedCategories={selectedCategories}
                                    handleSave={handleSave}
                                    handleClose={handleOpenClose}
                                />
                            </Stack>
                        </HeaderContainerStyled>
                        <Box mb="S">
                            <HorizontalLine color={Palette.Navy600} />
                        </Box>
                        {renderChart()}
                    </TileContainer>
                </Stack>
            )}
        </Stack>
    );
};
