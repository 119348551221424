import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { IImageData } from "../../../models/ReportImagesModels";
import { DefaultItemField, Direction, TextValueStyled } from "../DefaultItemField";
import ReportImagesCarousel from "../ReportImagesCarousel";

type PropsType = {
    fieldName: string;
    fieldValue?: IImageData;
    isRequired?: boolean;
    sharingId?: string;
    desktopDirection?: Direction;
    mobileDirection?: Direction;
};

export const ImageField = ({ fieldName, fieldValue, desktopDirection = "row", mobileDirection = "row", sharingId, isRequired = true }: PropsType) => {
    const { t, i18n } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    if (isRequired || fieldValue) {
        const labelValue = i18n.exists(fieldName) ? t(fieldName) : fieldName;
        return (
            <DefaultItemField alignItems="center" label={labelValue} direction={isMobile ? mobileDirection : desktopDirection}>
                {fieldValue ? (
                    <ReportImagesCarousel imagesData={[fieldValue]} sharingId={sharingId} />
                ) : (
                    <TextValueStyled>{t("common.noDataFound")}</TextValueStyled>
                )}
            </DefaultItemField>
        );
    }
    return <></>;
};
