import { Box, Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "src/components/shared/Tooltip";
import { compactNumber } from "src/helpers/NumberHelper";

import { CellWrapperStyled, TotalStyled } from "./OtherHeaderCell.styles";

type OtherHeaderCellProps = {
    totalCount: number;
};

export const OtherHeaderCell = ({ totalCount }: OtherHeaderCellProps) => {
    const { t } = useTranslation();

    return (
        <CellWrapperStyled disabled={true}>
            <Stack alignItems="center">
                <Stack>
                    <Box mr="XS">
                        <Text micro truncate={true} data-testid="matrix-table-data-category-name">
                            {t("incidentMatrix.table.otherColumn.header")}
                        </Text>
                    </Box>
                    <Tooltip
                        place="top-end"
                        message={
                            <Stack justifyContent="center" direction="column">
                                <span>{t("incidentMatrix.table.otherColumn.tooltip.contentDescription")}</span>
                                <span>{t("incidentMatrix.table.otherColumn.tooltip.actionDescription")}</span>
                            </Stack>
                        }
                    >
                        <Icon variant="Info" className="incident-matrix-other-column-target" />
                    </Tooltip>
                </Stack>
            </Stack>
            <TotalStyled small color="secondary" data-testid="matrix-table-data-category-total">
                {t("incidentMatrix.table.total")}: {compactNumber(totalCount)}
            </TotalStyled>
        </CellWrapperStyled>
    );
};
