import "pure-react-carousel/dist/react-carousel.es.css";

import { Breakpoints, Icon, useHasMaxWidth } from "@secuis/ccp-react-components";
import { StackProps } from "@secuis/ccp-react-components/dist/types/styles/stack/Stack";
import { useCallback, useRef, useState } from "react";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";

import { IImageData, ImageSizePaths } from "../../models/ReportImagesModels";
import { useReportImages } from "../../store/reports/ReportsHooks";
import Carousel from "../shared/Carousel";
import { Loading } from "../shared/Loading";
import CarouselFullScreen from "./CarouselFullScreen";
import { CarouselButton, ImagesWrapper, Thumbnail } from "./ReportImagesCarousel.styles";

type PropsType = StackProps & {
    imagesData: IImageData[];
    sharingId?: string;
    imageSize?: ImageSizePaths;
    className?: string;
};

const ReportImagesCarousel = ({ imagesData, sharingId, imageSize = ImageSizePaths.Small, className, ...otherProps }: PropsType) => {
    const { reportImages, loadingMultiple } = useReportImages(imagesData, imageSize, sharingId);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const imageWidth = (isMobile ? 116 : 124) + 16;
    const containerRef = useRef(null);
    const hasSingleImage = imagesData.length === 1;

    const showFullScreen = useCallback((index: number) => {
        setImageIndex(index);
        setIsModalOpen(true);
    }, []);

    const hideFullScreen = useCallback(() => {
        setImageIndex(0);
        setIsModalOpen(false);
    }, []);

    const handleKeyDown = useOnEnterSpaceKeyDown(showFullScreen);

    const renderCarousel = () => {
        if (hasSingleImage) {
            const picture = reportImages[0];
            return (
                <Thumbnail
                    hasSingleImage
                    className={className}
                    onClick={() => {
                        showFullScreen(0);
                    }}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                >
                    <img src={picture?.image} alt="" />
                </Thumbnail>
            );
        }
        return (
            <Carousel
                itemWidth={imageWidth}
                dataLength={imagesData.length}
                onClickItem={showFullScreen}
                nextButton={
                    <CarouselButton type="button">
                        <Icon color="accent" size="XL" variant="ArrowForward" />
                    </CarouselButton>
                }
                prevButton={
                    <CarouselButton isPrev type="button">
                        <Icon color="accent" size="XL" variant="ArrowBack" />
                    </CarouselButton>
                }
            >
                {reportImages.map((item, index) => (
                    <Thumbnail key={index} onKeyDown={(e) => handleKeyDown(e, index)}>
                        <img src={item.image} alt="" />
                    </Thumbnail>
                ))}
            </Carousel>
        );
    };

    return (
        <>
            <ImagesWrapper hasSingleImage={hasSingleImage} ref={containerRef} {...otherProps}>
                {loadingMultiple ? <Loading /> : renderCarousel()}
            </ImagesWrapper>
            {isModalOpen && <CarouselFullScreen imagesData={imagesData} imageIndex={imageIndex} onClose={hideFullScreen} sharingId={sharingId} />}
        </>
    );
};

export default ReportImagesCarousel;
