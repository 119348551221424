import "pure-react-carousel/dist/react-carousel.es.css";

import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { InvisibleScrollbar } from "../../styles/templates/Scrollbar";

const IMAGE_HEIGHT = 124;

export const ImagesWrapper = styled(Stack).attrs({
    alignItems: "center",
})<{ hasSingleImage?: boolean }>`
    max-width: 100%;

    div:nth-child(1n):not(.carousel-root) {
        max-width: 100%;
        ${({ hasSingleImage }) => (!hasSingleImage ? `margin-right: ${Spacing.S}px;` : "")}
    }

    @media (max-width: ${Breakpoints.XS}) {
        ${({ hasSingleImage }) => (!hasSingleImage ? `margin-right: -${Spacing.XS}px;` : "")} //go outside border to show there are more images
        overflow-x: scroll;
    }

    ${InvisibleScrollbar}
`;
export const Thumbnail = styled.div.attrs({
    tabIndex: 0,
    role: "button",
})<{ hasSingleImage?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${IMAGE_HEIGHT}px;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    ${({ hasSingleImage }) =>
        !hasSingleImage
            ? `
            max-height: ${IMAGE_HEIGHT}px;
            min-width: ${IMAGE_HEIGHT}px;
    `
            : ""}

    img {
        object-fit: scale-down;
        height: 100%;
    }

    &:focus-visible {
        outline-offset: -4px;
    }

    @media (max-width: ${Breakpoints.M}) {
        max-height: 116px;
        max-width: 116px;
        ${({ hasSingleImage }) =>
            !hasSingleImage
                ? `
            min-height: 116px;
            min-width: 116px;
    `
                : ""}
    }
`;

export const CarouselButton = styled.button<{ isPrev?: boolean }>`
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    cursor: pointer;
    width: 62px;
    border: 0px transparent;
    ${({ isPrev }) =>
        isPrev
            ? `
        background: linear-gradient(90deg, ${Palette.Navy650} 0%, rgba(35, 60, 89, 0.8412) 27.08%, rgba(35, 60, 89, 0.55) 54.17%, rgba(35, 60, 89, 0) 100%);
        left: 0;
    `
            : `
        background: linear-gradient(270deg, ${Palette.Navy650} 0%, rgba(35, 60, 89, 0.8412) 27.08%, rgba(35, 60, 89, 0.55) 54.17%, rgba(35, 60, 89, 0) 100%);
        right: 0;
    `}

    & > svg {
        ${({ isPrev }) => (isPrev ? `margin-right: 30px;` : `margin-left: 30px;`)}
    }
`;
