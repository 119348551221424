import { NavigationLinkModel } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { NavigationStyled } from "./Navbar.styles";

export const Navbar = () => {
    const { t } = useTranslation();
    const isClientSeverityLevelEnabled = useFeatureFlag(featureFlags.clientSeverityLevel);

    const links: NavigationLinkModel[] = [
        { to: "/insights", icon: "Insights", label: t("navbar.link.insights") },
        { to: "/reports", icon: "Report", label: t("navbar.link.reports") },
        { to: "/sites", icon: "Building", label: t("navbar.link.objects") },
    ];

    if (isClientSeverityLevelEnabled) {
        links.push({ to: "/settings", icon: "Settings", label: t("navbar.link.settings") });
    }

    return <NavigationStyled title={t("navigation.title")} isExpanded isExpandable links={links} id="aside-navbar-panel" />;
};
