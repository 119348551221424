import { Separator, Stack } from "@secuis/ccp-react-components";

import { PageHeadline } from "./components/PageHeadline";

export const ClientSeverityLevel = () => {
    return (
        <Stack direction="column" pt="S">
            <Separator />
            <PageHeadline />
        </Stack>
    );
};
