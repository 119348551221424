import { createContext, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { UserPreference } from "../../models/UserModel";
import { useSetUserPreference, useUserInfo } from "../../store/user/UserHooks";
import { Intro } from "./Onboarding.styles";
import { securitasLogo } from "./OnboardingLogoBase64";
import { OnbardingModal } from "./OnboardingModal";

interface OnboardingModalContextType {
    onboardingVisible: boolean;
}
export const OnboardingContext = createContext<OnboardingModalContextType>({
    onboardingVisible: false,
});

export const OnboardingModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [onboardingVisible, setOnboardingVisible] = useState(false);
    const { info: userInfo } = useUserInfo();
    const setUserPreference = useSetUserPreference();
    const [opaque, setOpaque] = useState(false);

    const onClose = () => {
        setOnboardingVisible(false);
        setUserPreference(UserPreference.OnboardingCompletedDate, new Date());
    };

    useEffect(() => {
        if (userInfo && !userInfo.preferences.onboardingCompletedDate) setOnboardingVisible(true);
    }, [userInfo]);

    setTimeout(() => {
        if (!opaque) setOpaque(true);
    }, 500);

    const onboardingContext: OnboardingModalContextType = useMemo(() => {
        return { onboardingVisible };
    }, [onboardingVisible]);

    return (
        <OnboardingContext.Provider value={onboardingContext}>
            {onboardingVisible && (
                <>
                    <OnbardingModal onClose={onClose} />
                    <Intro opaque={opaque}>
                        <img src={`data:image/png;base64,${securitasLogo}`} />
                    </Intro>
                </>
            )}
            {children}
        </OnboardingContext.Provider>
    );
};
