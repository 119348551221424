import { Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const IconStyled = styled(Icon)`
    margin-right: 4px;
`;

type Props = {
    count: number;
};

export const MissedCheckpoints = ({ count }: Props) => {
    const { t } = useTranslation();

    return (
        <Stack alignItems="center">
            <IconStyled size="S" variant="Missed" />
            <Text micro>
                {count} {t("reportlist.missed")}
            </Text>
        </Stack>
    );
};
