import { Survicate } from "@survicate/survicate-web-surveys-wrapper";
import { PropsWithChildren } from "react";

import { createContext } from "../createContext";
import { SURVICATE_PLACEHOLDER } from "./constants";
import { useSurvicateProvider } from "./hooks";

const [SurvicateContext, useSurvicate] = createContext<Survicate>({
    defaultValue: SURVICATE_PLACEHOLDER,
    providerName: "SurvicateProvider",
});

export { useSurvicate };

type Props = PropsWithChildren<{
    isAppReady?: boolean;
}>;

export const SurvicateProvider = ({ children, isAppReady = true }: Props) => {
    const survicateInstance = useSurvicateProvider(isAppReady);

    return <SurvicateContext.Provider value={survicateInstance}>{children}</SurvicateContext.Provider>;
};
