import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { Text } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import { useHistory } from "src/contexts/HistoryProvider";

import { ISiteObject } from "../../models/SiteObjectModel";
import { PageNames } from "../../models/tracking/pageView";
import { useAuthorizedLocations } from "../../store/locations/LocationsHooks";
import SitesActions from "../../store/sites/SitesActions";
import SitesSelectors from "../../store/sites/SitesSelectors";
import { useTrackPageVisit } from "../../store/tracking/TrackingHooks";
import { SitesGroupsList } from "./Groups/SitesGroupsList";
import { useFilteredSiteObjects } from "./hooks/SitesObjectsHooks";
import { ContainerStyled, FooterStyled, InfoBarStyled, RowStyled, WrapperStyled } from "./SitesPage.styles";
import { SitesPageHeader } from "./SitesPageHeader";
import { SitesTile } from "./SitesTile";

const DEFAULT_SITES_IN_ROW = 4;

export const SitesPage = () => {
    const [sitesInRow, setSitessInRow] = useState<number>(DEFAULT_SITES_IN_ROW);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { registerPageLabel } = useHistory();
    const selectedGroupId = useSelector(SitesSelectors.getSelectedGroupId);
    const { siteObjects, groups } = useAuthorizedLocations();
    const { filteredSiteObjects } = useFilteredSiteObjects(selectedGroupId);
    const isBreakpointXS = useHasMaxWidth(Breakpoints.XS);
    const isBreakpointL = useHasMaxWidth(Breakpoints.L);

    useEffect(() => {
        registerPageLabel(t("navbar.link.objects"));
    }, [registerPageLabel, t]);

    useEffect(() => {
        const sitesInRow = isBreakpointXS ? 1 : isBreakpointL ? 3 : 4;
        setSitessInRow(sitesInRow);
    }, [isBreakpointXS, isBreakpointL]);

    useTrackPageVisit(PageNames.SiteOverview, {
        "Has custom groups": groups.length > 0,
    });

    const virtualScrollContentList = useMemo(() => {
        return filteredSiteObjects.reduce((acc, siteObject, index) => {
            const rowIndex = Math.floor(index / sitesInRow);
            if (!acc[rowIndex]) {
                acc[rowIndex] = [];
            }
            acc[rowIndex].push(siteObject);
            return acc;
        }, []);
    }, [sitesInRow, filteredSiteObjects]);

    const handleGroupSelect = useCallback(
        (groupId: string) => {
            dispatch(SitesActions.setSelectedGroupId(groupId));
        },
        [dispatch],
    );

    const renderHeaderSection = () => {
        return <SitesPageHeader selectedGroupId={selectedGroupId} onGroupSelect={handleGroupSelect} />;
    };

    const renderFooterSection = () => <FooterStyled />;

    const renderRow = (index: number) => (
        <RowStyled>
            {virtualScrollContentList[index].map((siteObject: ISiteObject) => (
                <SitesTile key={siteObject.id} site={siteObject} />
            ))}
        </RowStyled>
    );

    const renderSitesGroupList = () => (
        <ContainerStyled>
            {siteObjects.length > 0 ? (
                <SitesGroupsList selectedGroupId={selectedGroupId} onGroupSelect={handleGroupSelect} />
            ) : (
                <InfoBarStyled>
                    <Text bold>{t("sites.list.accesses.sites", { sites: 0 })}</Text>
                </InfoBarStyled>
            )}
        </ContainerStyled>
    );

    return (
        <WrapperStyled data-testid="sites-page">
            {filteredSiteObjects.length > 0 ? (
                <Virtuoso
                    style={{ height: "100%" }}
                    totalCount={virtualScrollContentList.length}
                    itemContent={(index) => renderRow(index)}
                    components={{ Header: renderHeaderSection, Footer: renderFooterSection }}
                    tabIndex={-1}
                />
            ) : (
                <>
                    {renderHeaderSection()}
                    {renderSitesGroupList()}
                </>
            )}
        </WrapperStyled>
    );
};
