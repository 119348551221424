import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PatrolDeviations } from "src/pages/Insights/PatrolTasks/PatrolDeviations/PatrolDeviations";
import { getScannedCheckpointsPercentage } from "src/pages/Insights/PatrolTasks/PatrolTasks.helpers";
import { TotalPerformanceSection } from "src/pages/Insights/PatrolTasks/TotalPerformance/TotalPerformanceSection";
import { Tours } from "src/pages/Insights/PatrolTasks/Tours/Tours";
import { TABLE_TOTAL_KEY } from "src/store/insights/InsightsModel";
import { DeviationKeys } from "src/store/insights/PatrolDeviations/PatrolDeviationHelpers";
import { PatrolDeviationItem } from "src/store/insights/PatrolDeviations/PatrolDeviationsTypes";
import { RequestStatus } from "src/store/RequestStatus";

import * as PatrolDeviationsSelectors from "../../../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import * as ToursSelectors from "../../../../../store/insights/Tours/ToursSelectors";
import { StackStyled } from "../../InsightsSitePage.styled";
import { EmptyStateSitePage } from "../EmptyStateSitePage/EmptyStateSitePage";

const EXCLUDED_KEYS = [DeviationKeys.category, DeviationKeys.chartData, DeviationKeys.categoryLabel, DeviationKeys.children];

export const TasksTab = () => {
    const patrolDeviations = useSelector(PatrolDeviationsSelectors.getPatrolDeviations);
    const toursQueryStatus = useSelector(ToursSelectors.getLocationToursQueryStatus);
    const tours = useSelector(ToursSelectors.getLocationTours);
    const deviationsQueryStatus = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryStatus);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isTasksTabLoading = toursQueryStatus === RequestStatus.loading;
    const { siteId } = useParams<{ siteId?: string }>();

    const totalPerformanceData = useMemo(() => {
        if (!tours.length && !patrolDeviations.length) {
            return {
                exceptionsCount: 0,
                toursCount: 0,
                scannedCheckpointsPercent: 0,
                historicalExceptionsCount: 0,
                historicalToursCount: 0,
                historicalScannedCheckpointsPercent: 0,
            };
        }
        const totalTours = tours.find((x) => x.mysTourId === TABLE_TOTAL_KEY) || {
            count: 0,
            histCount: 0,
            checkpointsMissed: 0,
            checkpointsScanned: 0,
            histCheckpointsMissed: 0,
            histCheckpointsScanned: 0,
        };
        const totalDeviations = patrolDeviations.find((x) => x.category === TABLE_TOTAL_KEY) || {};
        const totalDeviationsValues = Object.keys(totalDeviations).reduce(
            (acc, key) => {
                if (EXCLUDED_KEYS.includes(DeviationKeys[key])) {
                    return acc;
                }
                return {
                    count: acc.count + (totalDeviations[key] as PatrolDeviationItem).count,
                    histCount: acc.histCount + (totalDeviations[key] as PatrolDeviationItem).histCount,
                };
            },
            { count: 0, histCount: 0 },
        );

        return {
            exceptionsCount: totalDeviationsValues.count,
            toursCount: totalTours.count,
            scannedCheckpointsPercent: getScannedCheckpointsPercentage(totalTours.checkpointsMissed, totalTours.checkpointsScanned),
            historicalExceptionsCount: totalDeviationsValues.histCount,
            historicalToursCount: totalTours.histCount,
            historicalScannedCheckpointsPercent: getScannedCheckpointsPercentage(totalTours.histCheckpointsMissed, totalTours.histCheckpointsScanned),
        };
    }, [tours, patrolDeviations]);

    const isEmptyPage =
        !totalPerformanceData.toursCount &&
        !totalPerformanceData.exceptionsCount &&
        !totalPerformanceData.historicalExceptionsCount &&
        !totalPerformanceData.historicalToursCount &&
        deviationsQueryStatus === RequestStatus.success;

    return (
        <>
            {isEmptyPage ? (
                <EmptyStateSitePage />
            ) : (
                <StackStyled ph={isMobile ? "S" : "L"} mt="L" direction="column" gap="S" flex={1}>
                    <TotalPerformanceSection hasSingleSite isLoading={isTasksTabLoading} {...totalPerformanceData} />
                    <PatrolDeviations
                        patrolDeviations={patrolDeviations}
                        isLoading={deviationsQueryStatus === RequestStatus.loading}
                        siteIds={[siteId]}
                        hasSingleSite
                    />
                    <Tours />
                </StackStyled>
            )}
        </>
    );
};
