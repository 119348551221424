import { Box, Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { compactNumber } from "src/helpers/NumberHelper";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { REPORT_CATEGORY_ICON_MAP } from "../../../../shared/constants";
import { CategoryCellStyled, CategoryIconStyled, CategoryNameStyled, CategoryTotalStyled } from "./CategoryCell.styles";

type CategoryCellProps = {
    categoryKey: string;
    totalCount: number;
    onClick?: () => void;
    disabled?: boolean;
};

export const CategoryCell = ({ categoryKey, totalCount, onClick, disabled = false }: CategoryCellProps) => {
    const { t } = useTranslation();
    const isNotLargeScreen = useHasMaxWidth(Breakpoints.L);
    const { isFilterActive } = useInsightsPageContext();
    const categoryIcon = REPORT_CATEGORY_ICON_MAP[categoryKey];

    const shouldShowCategoryName = !categoryIcon || !isNotLargeScreen;

    return (
        <CategoryCellStyled onClick={onClick} disabled={disabled}>
            <Stack alignItems="flex-start" justifyContent="center" direction="column">
                <Stack alignItems="center">
                    {categoryIcon && <CategoryIconStyled color="white" size={isNotLargeScreen ? "M" : "S"} variant={categoryIcon} />}

                    <Box paddingLeft={categoryIcon ? "M" : undefined}>
                        {shouldShowCategoryName && (
                            <CategoryNameStyled
                                isFilterActive={isFilterActive}
                                micro
                                truncate
                                data-category-name={categoryKey}
                                data-testid="matrix-table-data-category-name"
                            >
                                {t(`incident.category.${categoryKey}`)}
                            </CategoryNameStyled>
                        )}
                    </Box>
                </Stack>
                <CategoryTotalStyled small color="secondary" data-testid="matrix-table-data-category-total">
                    {t("incidentMatrix.table.total")}: {compactNumber(totalCount)}
                </CategoryTotalStyled>
            </Stack>
        </CategoryCellStyled>
    );
};
