import tz_lookup from "@photostructure/tz-lookup";
import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { uniq } from "lodash";
import { MouseEventHandler, useMemo, useRef, useState } from "react";
import { isSameDay } from "src/helpers/date";

import { UserPreference } from "../../../../../models/UserModel";
import { useAuthorizedLocations } from "../../../../../store/locations/LocationsHooks";
import { useSetUserPreference, useUserPreference } from "../../../../../store/user/UserHooks";
import { TIMES_USER_IS_NOTIFIED } from "./SiteZoneInfoTooltip.constants";
import { SiteZoneNoteStatus } from "./SiteZoneInfoTooltip.types";

export const useSiteZoneInfoTooltip = () => {
    const updateUserPreferences = useSetUserPreference();
    const [stringifiedStatus] = useUserPreference(UserPreference.SiteTimezoneDatepickerStatus);
    const status = useMemo(() => JSON.parse(stringifiedStatus || "{}") as SiteZoneNoteStatus, [stringifiedStatus]);
    const canUserSeeTheTooltip = useCanUserSeeTheTooltip(status);
    const [canSee, setCanSee] = useState(canUserSeeTheTooltip);
    const isNotDesktop = useHasMaxWidth(Breakpoints.M);
    const [isExplicitlyOpen, setIsExplicitlyOpen] = useState<boolean | undefined>(undefined);
    const containerRef = useRef<HTMLDivElement>(null);
    const hasUserAlreadyConfirmed = !!status.lastSeen;

    const confirmNoteAsRead = () => {
        setIsExplicitlyOpen(false);
        markAsSeenInUserPreferences();
        setCanSee(false);
    };

    const tooltipWidth = containerRef?.current?.clientWidth;

    const markAsSeenInUserPreferences = () => {
        updateUserPreferences(
            UserPreference.SiteTimezoneDatepickerStatus,
            JSON.stringify({
                lastSeen: new Date().toISOString(),
                count: (status.count || 0) + 1,
            }),
        );
    };

    const closeTooltip = () => {
        confirmNoteAsRead();
    };

    const showTooltip = () => {
        setIsExplicitlyOpen(true);
    };

    const triggerProps: Record<string, MouseEventHandler<HTMLDivElement>> = {
        onMouseEnter: canSee ? showTooltip : undefined,
        onMouseLeave: canSee && hasUserAlreadyConfirmed ? closeTooltip : undefined,
        onClickCapture:
            isNotDesktop && canSee
                ? (event) => {
                      showTooltip();
                      event.stopPropagation();
                  }
                : undefined,
    };

    return {
        tooltipWidth,
        triggerProps,
        confirmNoteAsRead,
        closeTooltip,
        isOpen: isExplicitlyOpen,
        containerRef,
    };
};

const useCanUserSeeTheTooltip = ({ lastSeen, count = 0 }: SiteZoneNoteStatus): boolean => {
    const hasMultiTimezonesSites = useDoesUserHaveSitesInManyTimezones();
    const hasNotSeenItBefore = !lastSeen;
    const hasNotSeenTheNoteToday = !isSameDay(new Date(lastSeen), new Date());
    const shouldRepeatTheNote = count < TIMES_USER_IS_NOTIFIED;

    return hasMultiTimezonesSites && (hasNotSeenItBefore || (hasNotSeenTheNoteToday && shouldRepeatTheNote));
};

const useDoesUserHaveSitesInManyTimezones = (): boolean => {
    const { siteObjects } = useAuthorizedLocations();

    const siteTimezones = useMemo(
        () =>
            siteObjects.map(({ coordinates }) => {
                const [latitude, longitude] = coordinates;

                let timezone!: string;

                try {
                    timezone = tz_lookup(latitude ?? 0, longitude ?? 0);
                } catch {
                    timezone = tz_lookup(0, 0);
                }
                return timezone;
            }),
        [siteObjects],
    );

    return uniq(siteTimezones).length > 1;
};
