import { Box, Breakpoints, ButtonCompact, ButtonIcon, ButtonText, Headline, Icon, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "src/components/shared/Tooltip";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { ViewModeSwitcher } from "../ViewModeSwitcher";
import { useSummaryHeader } from "./SummaryHeader.hooks";
import { NoWrapSpanStyled } from "./SummaryHeader.styles";

type Props = {
    isEditMode: boolean;
    isCustomized?: boolean;
    toggleEditMode: () => void;
    onSettingsSave: () => void;
    onSettingsReset: () => void;
};

export const SummaryHeader = ({ isEditMode, isCustomized, toggleEditMode, onSettingsSave, onSettingsReset }: Props) => {
    const { t } = useTranslation();
    const { periodLabel } = useSummaryHeader();
    const isTablet = useHasMaxWidth(Breakpoints.S);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isPersonalizeKpiSummaryEnabled = useFeatureFlag(featureFlags.personalizeKpiSummary);
    const isResetButtonVisible = isPersonalizeKpiSummaryEnabled && isEditMode && isCustomized;

    const renderTitle = () => (
        <Stack gap="XS" alignItems="center">
            <Headline data-test-id="summary-headline">
                {t("insights.summary.title")}: <NoWrapSpanStyled>{periodLabel}</NoWrapSpanStyled>
            </Headline>
            {isPersonalizeKpiSummaryEnabled && (
                <Tooltip message="Use the 'Customize' button to rearrange charts and hide elements as needed.">
                    <Icon variant="Info" />
                </Tooltip>
            )}
        </Stack>
    );

    const renderEditButton = () =>
        isMobile ? (
            <ButtonIcon icon="Edit" mode="outlined" onClick={toggleEditMode} />
        ) : (
            <ButtonCompact icon="Edit" mode="outlined" onClick={toggleEditMode}>
                {t("filters.customize")}
            </ButtonCompact>
        );

    const renderEditControls = () =>
        isEditMode ? (
            <Stack gap={isTablet ? "XS" : "S"} alignItems="center">
                <ButtonCompact color="accent" mode="contained" onClick={onSettingsSave}>
                    {t("common.save")}
                </ButtonCompact>
                <ButtonCompact mode="outlined" onClick={toggleEditMode}>
                    {t("common.cancel")}
                </ButtonCompact>
            </Stack>
        ) : (
            renderEditButton()
        );

    return (
        <>
            {isPersonalizeKpiSummaryEnabled && (
                <Box mb="XS" pb="XXS">
                    {renderTitle()}
                </Box>
            )}
            <Stack mb="S" gap="S" justifyContent="space-between" alignItems="center">
                {isPersonalizeKpiSummaryEnabled ? renderEditControls() : renderTitle()}
                <Stack alignItems="center" gap="M">
                    {isResetButtonVisible && (
                        <ButtonText icon="Reload" onClick={onSettingsReset}>
                            {t("insights.summary.settings.resetButton")}
                        </ButtonText>
                    )}
                    <ViewModeSwitcher />
                </Stack>
            </Stack>
        </>
    );
};
