import { Palette } from "@secuis/ccp-react-components";

import { DeviantDayWidget } from "./DeviantDayWidget";
import { DeviantHourWidget } from "./DeviantHourWidget";
import { GridItem, ItemPlacement } from "./DraggableGrid/DraggableGrid.types";
import { IncidentCategoriesWidget } from "./IncidentCategoriesWidget";
import { IncidentDistributionWidget } from "./IncidentDistributionWidget";
import { PerformanceIndicatorsWidget } from "./PerformanceIdicatorsWidget";
import { ReportsCountWidget } from "./ReportsCountWidget/ReportsCountWidget";
import { SiteEventsWidget } from "./SiteEventsWidget";
import { SiteReportsWidget } from "./SiteReportsWidget";
import { SummaryViewModeOption, WidgetViewModeOption } from "./Summary.types";

export const SEPARATOR_COLOR = `${Palette.Navy300}4d`;

export const SUMMARY_VIEW_MODE_OPTIONS: SummaryViewModeOption[] = [
    {
        labelTranslationKey: "insights.summary.viewMode.day",
        value: "day",
    },
    {
        labelTranslationKey: "insights.summary.viewMode.month",
        value: "month",
    },
];

export const WIDGET_VIEW_MODE_OPTIONS: WidgetViewModeOption[] = [
    {
        labelTranslationKey: "insights.summary.viewMode.most",
        value: "most",
    },
    {
        labelTranslationKey: "insights.summary.viewMode.least",
        value: "least",
    },
];

export const GRID_WIDGETS: (GridItem & ItemPlacement)[] = [
    {
        id: "performance-indicator",
        Content: PerformanceIndicatorsWidget,
        width: 3,
        responsiveSizing: {
            lg: { height: 1 },
            xs: { height: 1 },
            xxs: { height: 2 },
        },
        x: 0,
        y: 0,
    },
    { id: "reports-count", width: 1, height: 3, Content: ReportsCountWidget, x: 0, y: 1 },
    { id: "incident-categories", width: 1, height: 3, Content: IncidentCategoriesWidget, x: 1, y: 1 },
    { id: "site-reports", width: 1, height: 3, Content: SiteReportsWidget, x: 2, y: 1 },
    { id: "site-events", width: 1, height: 3, Content: SiteEventsWidget, x: 0, y: 4 },
    { id: "event-distribution", width: 1, height: 3, Content: IncidentDistributionWidget, x: 1, y: 4 },
    { id: "deviant-day", width: 1, height: 3, Content: DeviantDayWidget, x: 2, y: 4 },
    { id: "deviant-hour", width: 2, height: 3, Content: DeviantHourWidget, x: 0, y: 7 },
];
