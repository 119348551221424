import { WorkerExecResult } from "src/models/DbModel";
import { getParameterizedArray, getReportQueryTimeRangeCondition, getSqlStringifiedArrayFromObject } from "src/sql/scripts/queryUtilities";

import { SiteReportsCountData } from "./SiteReportsWidget.types";

export const getSiteReportsCountQuery = ({ siteIds, startDate, endDate }: { siteIds: string[]; startDate: Date; endDate: Date }) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT location_id, COUNT(*) as reportsCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND ${getReportQueryTimeRangeCondition(startDate, endDate)}
                AND location_id IN (${stringifiedSites})
            GROUP BY location_id
            ORDER BY reportsCount DESC
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseSiteReportsCountResult = ({ results }: WorkerExecResult): SiteReportsCountData => {
    const values = results[0]?.values;

    if (!values?.length) {
        return [];
    }

    return values.map((value) => ({
        locationId: value[0],
        totalCount: value[1],
    }));
};
