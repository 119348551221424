import { Icon, Palette, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const IconStyled = styled(Icon)`
    margin-right: 4px;
    color: ${Palette.Orange400};
`;

export const TextStyled = styled(Text)`
    color: ${Palette.Orange400};
`;
