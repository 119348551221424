import { useFilteredSites } from "src/store/insights/FilterHooks";

import { useSummaryViewMode } from "../shared/hooks/useSummaryViewMode";
import { GRID_CONFIGS_MAP } from "./SummaryGrid.constants";

export const useSummaryGrid = () => {
    const viewMode = useSummaryViewMode();
    const { siteIds } = useFilteredSites();
    const variant = siteIds.length === 1 ? "singleSite" : "multiSite";

    return {
        widgetsConfig: GRID_CONFIGS_MAP[viewMode][variant],
    };
};
