import { useDebounce } from "@secuis/ccp-react-components";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useCategoryInfo, useFetchDeviations } from "src/pages/Insights/shared/hooks";

import { CategoryIncidentCountPerSiteBarchart } from "../Barchart.types";
import { useFetchIncidents } from "./useFetchIncidents";

type UseMatrixDataResultBarchart = {
    data: CategoryIncidentCountPerSiteBarchart[];
    isLoading: boolean;
};

export const useBarchartData = (parentCategory: string, siteId: string, startDate: Date, endDate: Date): UseMatrixDataResultBarchart => {
    const { categories, level } = useCategoryInfo(parentCategory);
    const debouncedStartDate = useDebounce(startDate, 200);
    const debouncedEndDate = useDebounce(endDate, 200);

    const { queryResult: incidents, isLoading: isFetchIncidentsLoading } = useFetchIncidents(siteId, categories, level, parentCategory);
    const { deviations, isLoading: isFetchDeviationsLoading } = useFetchDeviations([siteId], categories, level, debouncedStartDate, debouncedEndDate);

    const isLoading = isFetchIncidentsLoading || isFetchDeviationsLoading;

    const deviationsList = useMemo(() => {
        const list = [];
        if (deviations[siteId]) {
            Object.keys(deviations[siteId]).forEach((key) => {
                list.push({ categoryKey: key, count: deviations[siteId][key] });
            });
        }
        return list;
    }, [deviations, siteId]);

    const combinedData = useMemo(() => {
        if (isLoading) return [];

        const combined = cloneDeep(incidents) as CategoryIncidentCountPerSiteBarchart[];

        deviationsList.forEach((deviation) => {
            const existing = combined.find((incident) => incident.categoryKey === deviation.categoryKey);
            if (existing) {
                existing.count += deviation.count;
            } else {
                combined.push(deviation);
            }
        });

        return combined;
    }, [isLoading, incidents, deviationsList]);

    return {
        data: combinedData,
        isLoading,
    };
};
