import { Breakpoints, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const PageWrapper = styled(Stack)`
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;

    @media (max-width: ${Breakpoints.XS}) {
        direction: column;
    }
`;
