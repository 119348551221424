import { ButtonText, Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAutoTranslationsActions, useAutoTranslationsState } from "./AutoTranslationProvider";
import { FULLY_TRANSLATED_LABEL, PARTIALLY_TRANSLATED_LABEL, TURN_OFF_TRANSLATION, TURN_ON_TRANSLATION } from "./constants";

type Props = {
    partiallyTranslated?: boolean;
};

export const AutoTranslatedContentFooter = ({ partiallyTranslated = true }: Props) => {
    const { isAutoTranslationsActive, isAutoTranslated } = useAutoTranslationsState();
    const { toggle } = useAutoTranslationsActions();
    const { t } = useTranslation();
    const message = partiallyTranslated ? PARTIALLY_TRANSLATED_LABEL : FULLY_TRANSLATED_LABEL;
    const buttonLabel = isAutoTranslationsActive ? TURN_OFF_TRANSLATION : TURN_ON_TRANSLATION;

    return (
        isAutoTranslated && (
            <FooterWrapperStyled data-testid="auto-translated-content-footer">
                <Stack alignItems="center" gap="XS">
                    {isAutoTranslationsActive && (
                        <>
                            <Icon color="secondary" variant="Translate" />
                            <Text color="secondary" micro={true}>
                                {t(message)}
                            </Text>
                        </>
                    )}
                </Stack>
                <ButtonText color="secondary" micro={true} onClick={toggle}>
                    {t(buttonLabel)}
                </ButtonText>
            </FooterWrapperStyled>
        )
    );
};

const FooterWrapperStyled = styled(Stack).attrs({
    paddingTop: "S",
    gap: "M",
    justifyContent: "space-between",
})`
    width: 100%;
`;
