import { keyBy, mapValues } from "lodash";
import { Layouts } from "react-grid-layout";

import { DEFAULT_COLUMNS_PER_BREAKPOINT, LAYOUT_BREAKPOINTS } from "./DraggableGrid.constants";
import { ColumnsPerBreakpoint, GridItem, ItemPlacement } from "./DraggableGrid.types";

export const getColumnsSetup = (columns: number | ColumnsPerBreakpoint): ColumnsPerBreakpoint => {
    let setup = DEFAULT_COLUMNS_PER_BREAKPOINT;

    if (columns) {
        if (typeof columns === "number") {
            setup = mapValues(setup, () => columns);
        } else {
            setup = columns;
        }
    }

    return setup;
};

export const prepareResponsiveLayoutConfig = (widgets: GridItem[], placement: ItemPlacement[]): Layouts => {
    const widgetsMap = keyBy(widgets, "id");

    return LAYOUT_BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
            [breakpoint]: placement.map(({ id, ...item }) => {
                const widget = widgetsMap[id];
                const width = widget?.responsiveSizing?.[breakpoint]?.width || widget.width || 1;
                const height = widget?.responsiveSizing?.[breakpoint]?.height || widget.height || 1;

                return { i: id, w: width, h: height, ...item };
            }),
            ...acc,
        }),
        {},
    );
};
