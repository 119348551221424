import { Box, Title } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { SwitcherBanner } from "../../../components/ClientSwitcher/SwitcherBanner";
import { useDisplaySwitcherBanner } from "../../../store/clientSwitcher/ClientSwitcherHooks";
import { SwitcherBannerWrapperStyled } from "./SettingsHeader.styles";

export const SettingsHeader = () => {
    const { t } = useTranslation();
    const isSwitcherBannerVisible = useDisplaySwitcherBanner();

    return (
        <Box mh={["L", "M", "S"]}>
            {isSwitcherBannerVisible && (
                <SwitcherBannerWrapperStyled>
                    <SwitcherBanner />
                </SwitcherBannerWrapperStyled>
            )}
            <Box marginVertical="M">
                <Title data-testid="settings-header">{t("settings.settings")}</Title>
            </Box>
        </Box>
    );
};
