import { groupBy, sum } from "lodash";
import { sortByField } from "src/helpers/ArrayHelper";
import { subMonths } from "src/helpers/date";
import { QueryResult } from "src/models/DbModel";
import { CustomizedCategories } from "src/pages/Insights/Events/TrendingEvents/TrendingEvents.types";

import { getDateTimeFieldName, getParameterizedArray, getSqlStringifiedArrayFromObject } from "../queryUtilities";

const getTrendingEvents = (siteIds: string[], categories: CustomizedCategories, monthsBack: number): { sql: string; params: unknown } => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    const dateTimeFieldName = getDateTimeFieldName();

    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const minDateForQuery = `date('${startOfMonth.toISOString()}','-${monthsBack} month')`;

    if (!categories || (!categories.level1Categories.length && !categories.level2Categories.length && !categories.level3Categories.length))
        return {
            sql: `
        SELECT count(r.id) as reportsCount, strftime("%m", r.${dateTimeFieldName}) as month, r.category_level2, r.template
        FROM reports r
        WHERE r.${dateTimeFieldName} >= ${minDateForQuery}
              AND r.location_id IN (${stringifiedSites})
              AND r.category_level2 IS NOT NULL
        GROUP BY month, r.category_level2
        ORDER BY month DESC;`,
            params: {
                ...siteIdsParams,
            },
        };
    return {
        sql: `
        ${
            (categories.level1Categories.length &&
                `
        SELECT count(r.id) as reportsCount, strftime("%m", r.${dateTimeFieldName}) as month, r.category_level1, r.category_level2, r.category_level3, r.template
        FROM reports r
        WHERE r.${dateTimeFieldName} >= ${minDateForQuery}
              AND r.location_id IN (${stringifiedSites})
              AND r.category_level2 IS NOT NULL
              AND r.category_level1 IN (${categories.level1Categories.map((c) => `'${c.value}'`).join(",")})
        GROUP BY month, r.category_level1`) ||
            ""
        }
        ${
            (categories.level2Categories.length &&
                ` ${(categories.level1Categories.length && "UNION ALL") || ""}
        SELECT count(r.id) as reportsCount, strftime("%m", r.${dateTimeFieldName}) as month, r.category_level1, r.category_level2, r.category_level3, r.template
        FROM reports r
        WHERE r.${dateTimeFieldName} >= ${minDateForQuery}
              AND r.location_id IN (${stringifiedSites})
              AND r.category_level2 IS NOT NULL
              AND r.category_level2 IN (${categories.level2Categories.map((c) => `'${c.value}'`).join(",")})
        GROUP BY month, r.category_level2`) ||
            ""
        }
        ${
            (categories.level3Categories.length &&
                `${((categories.level1Categories.length || categories.level2Categories.length) && "UNION ALL") || ""}
        SELECT count(r.id) as reportsCount, strftime("%m", r.${dateTimeFieldName}) as month, r.category_level1, r.category_level2, r.category_level3, r.template
        FROM reports r
        WHERE r.${dateTimeFieldName} >= ${minDateForQuery}
              AND r.location_id IN (${stringifiedSites})
              AND r.category_level2 IS NOT NULL
              AND r.category_level3 IN (${categories.level3Categories.map((c) => `'${c.value}'`).join(",")})
        GROUP BY month, r.category_level3`) ||
            ""
        }
        ORDER BY month DESC;`,
        params: {
            ...siteIdsParams,
        },
    };
};

const getTrendingEventResults = (queryResults: QueryResult[], categories: CustomizedCategories): { month: number; [key: string]: number }[] => {
    if (!queryResults?.length || !queryResults[0]) {
        return [];
    }

    const currentDate = new Date();
    const result = [
        { month: subMonths(currentDate, 6).getMonth() + 1 },
        { month: subMonths(currentDate, 5).getMonth() + 1 },
        { month: subMonths(currentDate, 4).getMonth() + 1 },
        { month: subMonths(currentDate, 3).getMonth() + 1 },
        { month: subMonths(currentDate, 2).getMonth() + 1 },
        { month: subMonths(currentDate, 1).getMonth() + 1 },
        { month: currentDate.getMonth() + 1 },
    ];

    if (categories?.level1Categories?.length || categories?.level2Categories?.length || categories?.level3Categories?.length) {
        result.forEach((r) => {
            categories?.level1Categories?.forEach((c) => {
                const value = queryResults[0].values.find((v) => v[2] === c.value && parseInt(v[1]) === r.month);
                r[c.value] = value ? value[0] : 0;
            });
            categories?.level2Categories?.forEach((c) => {
                const value = queryResults[0].values.find((v) => v[3] === c.value && parseInt(v[1]) === r.month);
                r[c.value] = value ? value[0] : 0;
            });
            categories?.level3Categories?.forEach((c) => {
                const value = queryResults[0].values.find((v) => v[4] === c.value && parseInt(v[1]) === r.month);
                r[c.value] = value ? value[0] : 0;
            });
        });
    } else {
        const groupedByCategories = groupBy(queryResults[0].values, (v) => v[2]);

        const allCategories = Object.keys(groupedByCategories)
            .map((key) => ({
                key,
                noOfEvents: sum(groupedByCategories[key].map((v) => v[0])),
            }))
            .sort(sortByField("noOfEvents", true));

        result.forEach((r) => {
            allCategories.forEach((c) => {
                const value = groupedByCategories[c.key]?.find((v) => parseInt(v[1]) === r.month);
                r[c.key] = value ? value[0] : 0;
            });
        });
    }

    return result;
};

export default {
    trendingEvents: {
        query: getTrendingEvents,
        getResults: getTrendingEventResults,
    },
};
