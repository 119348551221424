import { Breakpoints, ButtonText, Icon, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { DefaultItemFieldWrapper } from "src/components/Reports/DefaultItemField";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { ButtonTextStyled, TourExceptionFieldWrapper } from "./TourExceptionField.styles";

const ViewExceptionButton = ({ disabled, onClick }) => {
    const { t } = useTranslation();
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);

    return isTrueDeviationsEnabled ? (
        <ButtonTextStyled disabled={disabled} onClick={onClick}>
            <Stack alignItems="center" gap="XS">
                <Icon variant="Deviations" /> {t("reportdetails.patrolTour.viewException")}
            </Stack>
        </ButtonTextStyled>
    ) : (
        <ButtonText color="accent" disabled={disabled} onClick={onClick}>
            {t("reportdetails.patrolTour.viewException")}
        </ButtonText>
    );
};

export const TourExceptionField = ({ onClick, label, value, disabled = false }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <DefaultItemFieldWrapper>
            <Stack direction="column">
                <Stack justifyContent="space-between">
                    {label}
                    {!isMobile && <ViewExceptionButton onClick={onClick} disabled={disabled} />}
                </Stack>
                <Text>{value || t("common.noDataFound")}</Text>
                {isMobile && (
                    <Stack justifyContent="flex-end">
                        <ViewExceptionButton onClick={onClick} disabled={disabled} />
                    </Stack>
                )}
            </Stack>
        </DefaultItemFieldWrapper>
    );
};

export const TourMultiExceptionField = ({ onClick, label, value, disabled = false, isFirst, isLast }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <TourExceptionFieldWrapper isFirst={isFirst} isLast={isLast} direction="column" ph={["M", "S", "XS"]}>
            {" "}
            <Stack direction="column" mb={"S"}>
                <Stack justifyContent="space-between">
                    <Text>{label}</Text>
                    {isFirst && !isMobile && <ViewExceptionButton onClick={onClick} disabled={disabled} />}
                </Stack>
                <Text>{value || t("common.noDataFound")}</Text>
                {isLast && isMobile && (
                    <Stack justifyContent="flex-end">
                        <ViewExceptionButton onClick={onClick} disabled={disabled} />
                    </Stack>
                )}
            </Stack>
        </TourExceptionFieldWrapper>
    );
};
