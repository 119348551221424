import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { featureFlags } from "../data/featureFlags";
import { UserType } from "../models/tracking/tracking.model";
import ClientSwitcherSelectors from "../store/clientSwitcher/ClientSwitcherSelectors";
import LocationsSelectors from "../store/locations/LocationsSelectors";
import TrackingActions from "../store/tracking/TrackingActions";
import UserSelectors from "../store/user/UserSelectors";
import { useFeatureFlag } from "./featureFlags";

export const useTracking = () => {
    const dispatch = useDispatch();
    const [trackingInitialized, setTrackingInitialized] = useState(false);
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const isClientUser = useSelector(ClientSwitcherSelectors.getIsClientUser);
    const { siteObjects } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const { info: user } = useSelector(UserSelectors.getUserInfo);
    const mobileGuardingTrackingEnabled = useFeatureFlag(featureFlags.mobileGuardingTracking);

    const userType = useMemo(() => {
        if (user.isEmployee) {
            return UserType.Internal;
        }
        if (isClientUser) {
            return UserType.Global;
        }
        if (siteObjects.length > 1) {
            return UserType.MultiSite;
        }
        if (siteObjects.length === 1) {
            return UserType.SingleSite;
        }
        return null;
    }, [user, isClientUser, siteObjects]);

    const initializeTracking = useCallback(() => {
        if (trackingInitialized) {
            return;
        }

        dispatch(TrackingActions.initializeTracking(selectedClient, userType));
        setTrackingInitialized(true);
        let sites = [];
        if (mobileGuardingTrackingEnabled) {
            sites = siteObjects.reduce((result, site) => {
                const country = site.countryCode;
                const isMobileGuardingSite = site.siteTags.includes("Mobile");
                const existingEntry = result.find((item) => item.countryCode === country && item.mobileService === isMobileGuardingSite);
                if (existingEntry) {
                    existingEntry.siteIds.push(site.id);
                } else {
                    result.push({ countryCode: country, siteIds: [site.id], mobileService: isMobileGuardingSite });
                }
                return result;
            }, []);
        } else {
            sites = siteObjects.reduce((result, site) => {
                const country = site.countryCode;
                const existingEntry = result.find((item) => item.countryCode === country);
                if (existingEntry) {
                    existingEntry.siteIds.push(site.id);
                } else {
                    result.push({ countryCode: country, siteIds: [site.id], mobileService: null });
                }
                return result;
            }, []);
        }

        TrackingActions.sendReachAndAdoption(dispatch, user.id, selectedClient.id, selectedClient.name, userType, sites);
    }, [dispatch, user, selectedClient, siteObjects, trackingInitialized, userType, mobileGuardingTrackingEnabled]);

    return { initializeTracking, trackingInitialized };
};
