export const MAX_PEAK_DAYS_COUNT = 3;

export const WEEKDAYS_ORDER = ["1", "2", "3", "4", "5", "6", "0"];

export const STATIC_MAX_VALUE = 100;

export const STATIC_CHART_DATA = [
    {
        key: "Mo",
        label: "Monday",
        name: "Mo",
        value: 4,
    },
    {
        key: "Tu",
        label: "Tuesday",
        name: "Tu",
        value: 4,
    },
    {
        key: "We",
        label: "Wednesday",
        name: "We",
        value: 4,
    },
    {
        key: "Th",
        label: "Thursday",
        name: "Th",
        value: 4,
    },
    {
        key: "Fr",
        label: "Friday",
        name: "Fr",
        value: 4,
    },
    {
        key: "Sa",
        label: "Saturday",
        name: "Sa",
        value: 4,
    },
    {
        key: "Su",
        label: "Sunday",
        name: "Su",
        value: 4,
    },
];
