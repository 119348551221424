import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Breakpoints, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import { InsightsDateFilter } from "src/components/Insights/Filter/InsightsDateFilter";
import { InsightsFilter } from "src/components/Insights/Filter/InsightsFilter";
import { ExpandablePanel } from "src/components/shared/filters/FilterToolbar.styles";
import { PageWrapper } from "src/components/shared/PageWrapper";
import { PageNames } from "src/models/tracking/pageView";
import { useSetInsightFiltersByParams } from "src/store/insights/InsightHooks";
import { useTrackPageVisit } from "src/store/tracking/TrackingHooks";

import { InsightsWrapper, PageHeaderWrapper } from "../Common.styled";
import { useInsightsPageContext } from "../InsightsPageProvider";
import { InsightsFilterIcon } from "../shared/components/InsightsFilterIcon";
import useSelectDateRangeFromLocation from "../shared/hooks/useSelectDateRangeFromLocation";
import { DateFilterWrapper } from "./OverviewEvents.styles";
import { OverviewEventsContent } from "./OverviewEventsContent";

export const OverviewEvents = () => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { isMobileLayout, isFilterActive, setIsFilterActive } = useInsightsPageContext();

    useTrackPageVisit(PageNames.OverviewEvents);

    useSetInsightFiltersByParams();

    useSelectDateRangeFromLocation();

    return (
        <PageWrapper>
            {!isFilterActive && !isMobile && <InsightsFilterIcon />}
            {isFilterActive &&
                (isMobile ? (
                    <ExpandablePanel>
                        <InsightsFilter onClose={() => setIsFilterActive(false)} />
                    </ExpandablePanel>
                ) : (
                    <InsightsFilter onClose={() => setIsFilterActive(false)} />
                ))}

            <InsightsWrapper direction="column">
                <PageHeaderWrapper isFilterActive={isFilterActive} paddingTop={Spacing.S}>
                    <DateFilterWrapper>{!isMobileLayout && <InsightsDateFilter onCalendarOpen={() => setIsFilterActive(false)} />}</DateFilterWrapper>
                </PageHeaderWrapper>
                <OverviewEventsContent />
            </InsightsWrapper>
        </PageWrapper>
    );
};
