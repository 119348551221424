import { Breakpoints, Palette, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Rectangle, RectangleProps, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import type { BarRectangleItem } from "recharts/types/cartesian/Bar";
import { GraphDataItem } from "src/helpers/graphs";
import { useClickOutsideNode } from "src/hooks/CommonHooks";
import { CustomizedGraphTooltip } from "src/pages/Insights/shared/components/CustomizedGraphTooltip";

import { SEPARATOR_COLOR } from "../../../Summary.constants";
import { GRAPH_MAX_HEIGHT_VERTICAL, GRAPH_MIN_HEIGHT, STYLES } from "./Graph.constants";
import { useBarChart, useTickFormatter, useTooltip } from "./Graph.hooks";
import type { GraphData } from "./Graph.types";

type Props = {
    data: GraphData;
    maxValue?: number;
    barSize?: "sm" | "md";
    tickCount?: number;
    highlightedValue?: number;
    isVertical?: boolean;
};

const CONTAINER_HEIGHT_DEFAULT = "100%";

export const Graph = ({ data, maxValue, barSize, tickCount = 5, highlightedValue, isVertical }: Props) => {
    const [containerHeight, setContainerHeight] = useState<number | string>(CONTAINER_HEIGHT_DEFAULT);
    const [activeBarIndex, setActiveBarIndex] = useState<number>(null);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { chartData, chartMaxBarSize, YAxisDomain, YAxisTicks } = useBarChart(data, maxValue, tickCount, barSize);
    const { tooltipPosition, isTooltipVisible, setTooltipSize, setIsTooltipVisible, updateActiveBarPosition } = useTooltip(isVertical);
    const { yAxisWidth, tickFormatter } = useTickFormatter(isVertical);

    const barMinPointSize = useMemo(() => {
        return data.some((x) => x.value === 0) ? 0 : 1;
    }, [data]);

    const handleTooltipWidthChange = useCallback(
        (width: number) => {
            if (width) {
                setTooltipSize((prev) => {
                    return { ...prev, width };
                });
            }
        },
        [setTooltipSize],
    );

    const handleTooltipHeightChange = useCallback(
        (height: number) => {
            if (height) {
                setTooltipSize((prev) => {
                    return { ...prev, height };
                });
            }
        },
        [setTooltipSize],
    );

    const handleBarClick = (barRectangleItem: BarRectangleItem, barIndex: number) => {
        const { onItemClick } = data[barIndex];

        if (onItemClick) {
            return onItemClick();
        }

        if (isMobile) {
            setActiveBarIndex(barIndex);
            updateActiveBarPosition(barRectangleItem);
            setIsTooltipVisible(true);
        }
    };

    const handleBarMouseEnter = (barRectangleItem: BarRectangleItem, barIndex: number) => {
        if (!isMobile) {
            setActiveBarIndex(barIndex);
            updateActiveBarPosition(barRectangleItem);
            setIsTooltipVisible(true);
        }
    };

    const handleBarMouseLeave = () => {
        if (!isMobile) {
            setActiveBarIndex(null);
            setIsTooltipVisible(false);
        }
    };

    const handleContainerResize = (width: number, height: number) => {
        setContainerHeight(height);
    };

    const handleBarChartClick = useCallback(
        (_, event) => {
            if (isMobile && !event.target.classList.contains("recharts-rectangle")) {
                setIsTooltipVisible(false);
                setActiveBarIndex(null);
            }
        },
        [isMobile, setIsTooltipVisible],
    );

    const { nodeRef, handleClickOutsideNode } = useClickOutsideNode(() => {
        setIsTooltipVisible(false);
        setActiveBarIndex(null);
    });

    const handleWrapperClick = useCallback(
        (event) => {
            if (isMobile) {
                handleClickOutsideNode(event);
            }
        },
        [isMobile, handleClickOutsideNode],
    );

    const renderBar = useCallback(
        (props: RectangleProps & GraphDataItem & { index: number }) => {
            let fillColor = props.fill;

            if (props.index === activeBarIndex && (props.onItemClick || props.tooltip)) {
                const barVariant = highlightedValue && highlightedValue === props.value ? "highlight" : "default";
                fillColor = STYLES.barColor[barVariant][isMobile ? "active" : "hover"];
            }

            return <Rectangle {...props} fill={fillColor} />;
        },
        [activeBarIndex, highlightedValue, isMobile],
    );

    const customTooltipRenderer = useCallback(
        (payload: Record<string, unknown>) => {
            const tooltipText = payload?.payload?.[0]?.payload.tooltip;

            if (!tooltipText) {
                return null;
            }

            return (
                <CustomizedGraphTooltip
                    active={isTooltipVisible}
                    content={tooltipText}
                    widthHandler={handleTooltipWidthChange}
                    heightHandler={handleTooltipHeightChange}
                />
            );
        },
        [isTooltipVisible, handleTooltipHeightChange, handleTooltipWidthChange],
    );

    return (
        // NOTE: Simplify wrappers and styling
        <Stack direction="column" style={{ width: "100%" }} flex={1} ref={nodeRef} onClick={handleWrapperClick}>
            <ResponsiveContainer
                width="100%"
                height={containerHeight}
                minHeight={GRAPH_MIN_HEIGHT}
                maxHeight={isVertical ? GRAPH_MAX_HEIGHT_VERTICAL : undefined}
                onResize={handleContainerResize}
            >
                <BarChart
                    layout={isVertical ? "vertical" : "horizontal"}
                    margin={isVertical ? STYLES.barChartMarginVertical : STYLES.barChartMargin}
                    data={chartData}
                    maxBarSize={chartMaxBarSize}
                    onMouseDown={handleBarChartClick}
                >
                    <XAxis
                        dataKey={isVertical ? "value" : "name"}
                        type={isVertical ? "number" : "category"}
                        hide={isVertical ? true : false}
                        interval={0}
                        tickMargin={10}
                        axisLine={false}
                        tickLine={false}
                        tick={STYLES.axisTick}
                        style={STYLES.axisStyle}
                    />
                    <YAxis
                        dataKey={isVertical ? "name" : "value"}
                        type={isVertical ? "category" : "number"}
                        scale={isVertical ? "auto" : "linear"}
                        tickMargin={isVertical ? 0 : 8}
                        tickCount={tickCount}
                        tickFormatter={tickFormatter}
                        ticks={YAxisTicks}
                        domain={YAxisDomain}
                        width={yAxisWidth}
                        axisLine={false}
                        tickLine={false}
                        tick={STYLES.axisTick}
                        style={STYLES.axisStyle}
                    />
                    <Tooltip
                        trigger={isMobile ? "click" : undefined}
                        content={customTooltipRenderer}
                        cursor={false}
                        offset={0}
                        position={tooltipPosition}
                        wrapperStyle={STYLES.tooltipWrapperStyle}
                        animationEasing="ease"
                    />
                    {!isVertical && <CartesianGrid stroke={SEPARATOR_COLOR} vertical={false} />}
                    <Bar
                        dataKey="value"
                        activeBar={renderBar}
                        radius={isVertical ? [0, 2, 2, 0] : [2, 2, 0, 0]}
                        minPointSize={barMinPointSize}
                        onClick={handleBarClick}
                        onMouseEnter={handleBarMouseEnter}
                        onMouseLeave={handleBarMouseLeave}
                    >
                        {isVertical && <LabelList dataKey="secondLabel" position="right" offset={4} fill={Palette.White} style={STYLES.labelStyle} />}
                        {chartData.map((item, index) => (
                            <Cell key={`cell-${index}`} cursor={item.tooltip ? "pointer" : undefined} fill={item.color || STYLES.barColor.default.default} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    );
};
