import onboarding1 from "../../assets/images/onboarding/onboarding_1.jpg";
import onboarding1GraphicsDesktop from "../../assets/images/onboarding/onboarding_1_graphics_desktop.png";
import onboarding1GraphicsMobile from "../../assets/images/onboarding/onboarding_1_graphics_mobile.png";
import onboarding2 from "../../assets/images/onboarding/onboarding_2.jpg";
import onboarding2GraphicsDesktop from "../../assets/images/onboarding/onboarding_2_graphics_desktop.png";
import onboarding2GraphicsMobile from "../../assets/images/onboarding/onboarding_2_graphics_mobile.png";
import onboarding3 from "../../assets/images/onboarding/onboarding_3.jpg";
import onboarding3GraphicsDesktop from "../../assets/images/onboarding/onboarding_3_graphics_desktop.png";
import onboarding3GraphicsMobile from "../../assets/images/onboarding/onboarding_3_graphics_mobile.png";
import onboarding4 from "../../assets/images/onboarding/onboarding_4.jpg";
import onboarding4GraphicsDesktop from "../../assets/images/onboarding/onboarding_4_graphics_desktop.png";
import onboarding4GraphicsMobile from "../../assets/images/onboarding/onboarding_4_graphics_mobile.png";

export const SLIDES = [
    {
        backgroundImageSrc: onboarding1,
        graphicsSrc: onboarding1GraphicsDesktop,
        graphicsSrcMobile: onboarding1GraphicsMobile,
        graphicsWidth: "75%",
        header: "onboarding.slides.1.header",
        subheader: "onboarding.slides.1.subheader",
    },
    {
        backgroundImageSrc: onboarding2,
        graphicsSrc: onboarding2GraphicsDesktop,
        graphicsSrcMobile: onboarding2GraphicsMobile,
        graphicsWidth: "95%",
        header: "onboarding.slides.2.header",
        backgroundPosition: "bottom",
    },
    {
        backgroundImageSrc: onboarding3,
        graphicsSrc: onboarding3GraphicsDesktop,
        graphicsSrcMobile: onboarding3GraphicsMobile,
        graphicsWidth: "77%",
        header: "onboarding.slides.3.header",
        backgroundPosition: "bottom",
    },
    {
        backgroundImageSrc: onboarding4,
        graphicsSrc: onboarding4GraphicsDesktop,
        graphicsSrcMobile: onboarding4GraphicsMobile,
        graphicsWidth: "75%",
        header: "onboarding.slides.4.header",
        backgroundPosition: "top",
    },
];
