import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMilliseconds, differenceInDays, differenceInMilliseconds, startOfMonth, subMilliseconds, subMonths } from "src/helpers/date";

import { RequestStatus } from "../../RequestStatus";
import InsightsSelectors from "../InsightsSelectors";
import { generateQueryKey } from "../keys";
import {
    formatTableData,
    getDataTimeRange,
    getDatesArray,
    mapDailyPatrolDeviationResponse,
    mapHourlyPatrolDeviationResponse,
    mapTotalDeviations,
} from "./PatrolDeviationHelpers";
import * as PatrolDeviationsActions from "./PatrolDeviationsActions";
import * as PatrolDeviationsSelectors from "./PatrolDeviationsSelectors";
import { CategoryKey, PatrolDeviationData } from "./PatrolDeviationsTypes";

export const usePatrolDeviations = (siteIds: string[]) => {
    const dispatch = useDispatch();
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const queryKey = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryKey);
    const queryStatus = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryStatus);

    const prevDateRange = useMemo(() => {
        const diff = differenceInMilliseconds(selectedStartDate, selectedEndDate);
        return {
            prevStartDate: new Date(addMilliseconds(selectedStartDate, diff)),
            prevEndDate: new Date(addMilliseconds(selectedStartDate, -1)),
        };
    }, [selectedStartDate, selectedEndDate]);

    const requestHourlyDeviations = useCallback(async () => {
        if (!siteIds.length) {
            return [];
        }
        const actualResult: unknown = await dispatch(
            PatrolDeviationsActions.requestPatrolDeviationsComparePeriodHourly({
                siteIds: siteIds,
                fromDateTime: selectedStartDate,
                toDateTime: selectedEndDate,
            }),
        );
        const historicalResult: unknown = await dispatch(
            PatrolDeviationsActions.requestPatrolDeviationsComparePeriodHourly({
                siteIds: siteIds,
                fromDateTime: prevDateRange.prevStartDate,
                toDateTime: prevDateRange.prevEndDate,
            }),
        );
        if (historicalResult["error"] || actualResult["error"]) {
            return [];
        }
        return mapHourlyPatrolDeviationResponse(historicalResult["payload"], actualResult["payload"], CategoryKey.categoryLevel1);
    }, [selectedStartDate, selectedEndDate, prevDateRange, siteIds, dispatch]);

    const requestDailyDeviations = useCallback(
        async (categoryLevel1?: string) => {
            if (!siteIds.length) {
                return [];
            }
            const actualResult: unknown = await dispatch(
                PatrolDeviationsActions.requestPatrolDeviationsComparePeriodDaily(
                    {
                        siteIds: siteIds,
                        fromDateTime: selectedStartDate,
                        toDateTime: selectedEndDate,
                    },
                    categoryLevel1,
                ),
            );
            const historicalResult: unknown = await dispatch(
                PatrolDeviationsActions.requestPatrolDeviationsComparePeriodDaily(
                    {
                        siteIds: siteIds,
                        fromDateTime: prevDateRange.prevStartDate,
                        toDateTime: prevDateRange.prevEndDate,
                    },
                    categoryLevel1,
                ),
            );
            if (historicalResult["error"] || actualResult["error"]) {
                return [];
            }
            return mapDailyPatrolDeviationResponse(
                historicalResult["payload"],
                actualResult["payload"],
                categoryLevel1 ? CategoryKey.categoryLevel2 : CategoryKey.categoryLevel1,
                getDatesArray(selectedStartDate, selectedEndDate),
            );
        },
        [selectedStartDate, selectedEndDate, prevDateRange, siteIds, dispatch],
    );

    const loadDailyDeviationsByCategoryLevel1Async = useCallback(
        async (category: string) => {
            dispatch(PatrolDeviationsActions.requestPatrolDeviationsLevel2());
            const result = await requestDailyDeviations(category);
            const range = getDataTimeRange(selectedStartDate, selectedEndDate);
            const formattedResult = formatTableData(result, range, false);
            dispatch(PatrolDeviationsActions.patrolDeviationsLevel2Success(formattedResult, category));
        },
        [selectedStartDate, selectedEndDate, dispatch, requestDailyDeviations],
    );

    useEffect(() => {
        const fetchData = async () => {
            dispatch(PatrolDeviationsActions.requestPatrolDeviations());
            if (!siteIds.length) {
                dispatch(PatrolDeviationsActions.patrolDeviationsSuccess([], key));
                return;
            }
            let result: PatrolDeviationData[];
            if (differenceInDays(selectedStartDate, selectedEndDate) > -1) {
                result = await requestHourlyDeviations();
            } else {
                result = await requestDailyDeviations();
            }
            const range = getDataTimeRange(selectedStartDate, selectedEndDate);
            const formattedResult = formatTableData(result, range);
            dispatch(PatrolDeviationsActions.patrolDeviationsSuccess(formattedResult, key));
        };
        const key = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        if (key !== queryKey && queryStatus !== RequestStatus.loading) {
            fetchData();
        }
    }, [selectedStartDate, queryKey, selectedEndDate, prevDateRange, siteIds, requestHourlyDeviations, requestDailyDeviations, dispatch, queryStatus]);

    return { loadDailyDeviationsByCategoryLevel1Async };
};

export const useTotalDeviations = (siteIds: string[], currentDateRange: Date[], controlPeriodInMonths: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalDeviations, setTotalDeviations] = useState([]);
    const queryKeyRef = useRef(null);
    const dispatch = useDispatch();
    const prevDateRange = useMemo(() => {
        const histEndDate = startOfMonth(subMonths(new Date(), controlPeriodInMonths));
        const histStartDate = startOfMonth(subMonths(histEndDate, controlPeriodInMonths));
        return [histStartDate, subMilliseconds(histEndDate, 1)];
    }, [controlPeriodInMonths]);

    const requestTotalDeviations = async () => {
        const actualResult: unknown = await dispatch(
            PatrolDeviationsActions.requestPatrolDeviationsComparePeriodDaily({
                siteIds: siteIds,
                fromDateTime: currentDateRange[0],
                toDateTime: currentDateRange[1],
            }),
        );
        const historicalResult: unknown = await dispatch(
            PatrolDeviationsActions.requestPatrolDeviationsComparePeriodDaily({
                siteIds: siteIds,
                fromDateTime: prevDateRange[0],
                toDateTime: prevDateRange[1],
            }),
        );
        if (historicalResult["error"] || actualResult["error"]) {
            return [];
        }
        return [
            mapTotalDeviations(actualResult["payload"], currentDateRange[0], currentDateRange[1]),
            mapTotalDeviations(historicalResult["payload"], prevDateRange[0], prevDateRange[1]),
        ];
    };

    useEffect(() => {
        const key = generateQueryKey({
            locationIds: siteIds,
            fromDate: currentDateRange[0],
            toDate: currentDateRange[1],
        });
        const fetchData = async () => {
            setIsLoading(true);
            if (!siteIds.length) {
                return;
            }
            const result = await requestTotalDeviations();
            setTotalDeviations(result);
        };
        if (key !== queryKeyRef.current && !isLoading) {
            fetchData();
            setIsLoading(false);
            queryKeyRef.current = key;
        }
    }, [prevDateRange, siteIds, currentDateRange, isLoading]);

    return { totalDeviations, isLoading };
};
