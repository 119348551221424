import { useEffect, useState } from "react";

import { useMemoizedDependency } from "../../hooks/useMemoizedDependency";
import { Databases, WorkerExecResult } from "../../models/DbModel";
import { useDatabase } from "../../store/insights/InsightsLoadHooks";

export const useSqlQuery = <T, P extends object>(
    getQueryFn: (params: P) => {
        sql: string;
        params: Record<string, unknown>;
    },
    resultParser: (result: WorkerExecResult) => T,
    params: P,
): {
    isLoading: boolean;
    queryResult: T | null;
} => {
    const [isLoading, setIsLoading] = useState(true);
    const [resultData, setResultData] = useState<T | null>(null);
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const memoizedParams = useMemoizedDependency(params);

    useEffect(() => {
        setIsLoading(true);

        const runSqlQuery = async () => {
            const query = getQueryFn(memoizedParams);
            try {
                const result = await execute(query.sql, query.params);
                const parsedResult = resultParser(result);

                setResultData(parsedResult);
            } catch (e) {
                console.error("SQL query failed", e);
            } finally {
                setIsLoading(false);
            }
        };

        if (execute && dbReadyToQuery) {
            runSqlQuery();
        }
    }, [execute, dbReadyToQuery, memoizedParams, setIsLoading, setResultData, getQueryFn, resultParser]);

    return { isLoading, queryResult: resultData };
};
