import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import TrackingActions from "src/store/tracking/TrackingActions";

import { featureFlags } from "../../../data/featureFlags";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import InsightsActions from "../../../store/insights/InsightsActions";
import InsightsSelectors from "../../../store/insights/InsightsSelectors";
import { STATIC_INSIGHTS_TABS, SUMMARY_TAB } from "./InsightsTabs.constants";
import { SubpageTab } from "./InsightsTabs.types";

export const useInsightsTabs = () => {
    const dispatch = useDispatch();
    const activeTab = useSelector(InsightsSelectors.getSelectedTab);
    const isSummaryEnabled = useFeatureFlag(featureFlags.kpiSummary);

    const tabs: SubpageTab[] = useMemo(() => {
        let updatedTabs = [...STATIC_INSIGHTS_TABS];

        if (isSummaryEnabled) {
            updatedTabs = [SUMMARY_TAB, ...updatedTabs];
        }

        return updatedTabs;
    }, [isSummaryEnabled]);

    const setActiveTab = (tabIndex: number) => dispatch(InsightsActions.setSelectedTab(tabIndex));

    const handleTabView = (index: number, isFirstView = false) => {
        dispatch(
            TrackingActions.trackEvent(MixpanelEvent.InsightsTabView, {
                Page: window.location.pathname,
                "Page Title": "Insights",
                Tab: tabs[index]?.trackingName,
                "Previous Tab": isFirstView ? null : tabs[activeTab]?.trackingName,
            }),
        );
    };

    useEffect(() => {
        handleTabView(activeTab, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        activeTab,
        activeTabLabelKey: tabs?.[activeTab]?.labelKey,
        setActiveTab,
        tabs,
        handleTabView,
    };
};
