import { Breakpoints, ButtonText, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const ButtonTextStyled = styled(ButtonText)`
    color: ${Palette.Orange300};

    svg {
        color: ${Palette.Orange300};
    }

    &:hover {
        color: ${Palette.Orange400};

        svg {
            color: ${Palette.Orange400};
        }
    }
`;

export const TourExceptionFieldWrapper = styled(Stack)<{ isFirst: boolean; isLast: boolean }>`
    background-color: ${Palette.Navy650};

    ${(props) =>
        props.isFirst &&
        `border-top-right-radius: ${Spacing.XXS}px; 
        border-top-left-radius: ${Spacing.XXS}px; 
        padding-top: ${Spacing.S}px;`}

    ${(props) =>
        props.isLast &&
        `border-bottom-right-radius: ${Spacing.XXS}px; 
        border-bottom-left-radius: ${Spacing.XXS}px; 
        margin-bottom: ${Spacing.S}px;`}

    @media (max-width: ${Breakpoints.M}) {
        ${(props) => props.isFirst && `padding-top: ${Spacing.XS}px;`}
    }
`;
