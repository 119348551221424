import { Breadcrumb, Breadcrumbs, Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { useMaxBreadcrumbsItems } from "./hooks/SitesBreadcrumbs.hooks";
import { BoxStyled } from "./SitesBreadcrumbs.styles";
import type { ISiteGroup } from "./types";

export type ISiteGroupAncestor = Pick<ISiteGroup, "id" | "name" | "descendantSitesCount">;

type Props = {
    selectedGroupAncestors: ISiteGroupAncestor[];
    onGroupSelect: (groupId: string) => void;
};

export const SitesBreadcrumbs = ({ selectedGroupAncestors, onGroupSelect }: Props) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { wrapperRef, breadcrumbRef, maxItems } = useMaxBreadcrumbsItems(selectedGroupAncestors.length);

    return (
        <BoxStyled ref={wrapperRef} data-testid="sites-breadcrumbs">
            <Breadcrumbs maxItems={maxItems} sheetCancelLabel={t("common.cancel")} ref={breadcrumbRef}>
                {selectedGroupAncestors.map((group, index) => {
                    const isLast = index === selectedGroupAncestors.length - 1;
                    const siteCount = group.descendantSitesCount;
                    const siteCountLabel = isLast ? ` ${t("sites.site", { count: siteCount })}` : "";
                    const breadcrumbLabel = `${group.name}${isMobile && !isLast ? "" : ` (${siteCount}${siteCountLabel})`}`;
                    return (
                        <Breadcrumb data-testid="sites-breadcrumbs-item" key={group.id + index} onClick={() => onGroupSelect(group.id)}>
                            {breadcrumbLabel}
                        </Breadcrumb>
                    );
                })}
            </Breadcrumbs>
        </BoxStyled>
    );
};
