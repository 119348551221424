import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import { flexColumn } from "src/styles/templates/Flex";
import styled from "styled-components";

export const Wrapper = styled.div<{ width: number; navbarWidth: number; isSingleReport?: boolean }>`
    position: absolute;
    overflow: hidden;
    top: 0;
    height: 100%;
    width: ${({ width, navbarWidth, isSingleReport }) =>
        isSingleReport ? `calc(100% - ${Spacing.XXXL}px)` : width ? `${width}px` : `calc(100% - ${navbarWidth}px)`};
    z-index: 10;
    ${flexColumn}
    background-color: ${Palette.Navy700};
    right: 0;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
    }

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.M}) {
        width: ${({ width, navbarWidth, isSingleReport }) =>
            isSingleReport ? `calc(100% - ${Spacing.XXXL}px - ${Spacing.M}px)` : width ? `${width}px` : `calc(100% - ${navbarWidth}px)`};
    }
`;

export const SingleReportView = styled.div`
    @media (min-width: ${Breakpoints.L}) {
        & > div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: max-content;
            gap: 0;

            & > :nth-child(1) {
                grid-row: 1;
            }

            & > :nth-child(2) {
                grid-column: 1 / -1;
                grid-row: 2;
                margin-bottom: ${Spacing.M}px;
            }

            & > :nth-child(3) {
                grid-column: 1 / -1;
                grid-row: 3;
            }

            & > :nth-child(4) {
                grid-column: 1 / 2;
                grid-row: 3;
            }

            & > :nth-child(5) {
                grid-column: 2 / 2;
                grid-row: 3;
            }
        }
    }
`;
