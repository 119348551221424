import { Box, Breakpoints, Stack, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BackButton } from "src/components/shared/BackButton/BackButton";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";
import { InsightsFilterIcon } from "src/pages/Insights/shared/components/InsightsFilterIcon";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { REPORT_CATEGORY_ICON_MAP } from "../../../../shared/constants";
import { useBreadcrumbs } from "./Breadcrumbs.hooks";
import { BackButtonWrapperStyled, IconStyled, StackStyled } from "./Breadcrumbs.styles";

type BreadcrumbsProps = {
    categoryKey: string;
};

export const Breadcrumbs = ({ categoryKey }: BreadcrumbsProps) => {
    const { t } = useTranslation();

    const { isFilterActive } = useInsightsPageContext();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const matrixCategoryLevel = useSelector(InsightsSelectors.getIncidentMatrixLevel);
    const breadcrumbs = useBreadcrumbs(categoryKey);
    const categoryLevel1Icon = breadcrumbs.length > 0 ? REPORT_CATEGORY_ICON_MAP[breadcrumbs[0]] : null;
    const backButtonDefaultLabel = `${t(`incident.category.${breadcrumbs[0]}`)} ${t("incidentMatrix.table.backButton.events")}`;
    const backButtonDefaultPath = `/insights/overview-events/${breadcrumbs[0]}`;

    return (
        <Box>
            {matrixCategoryLevel > 1 && (
                <Stack justifyContent="space-between">
                    <BackButtonWrapperStyled>
                        <BackButton defaultLabel={backButtonDefaultLabel} defaultPath={backButtonDefaultPath} />
                    </BackButtonWrapperStyled>
                    {!isFilterActive && isMobile && <InsightsFilterIcon />}
                </Stack>
            )}
            <StackStyled alignItems="center">
                {categoryLevel1Icon && <IconStyled variant={categoryLevel1Icon} size="L" />}
                <Stack>
                    <Title>
                        {breadcrumbs.map((breadcrumbCategoryKey) => {
                            return <span key={breadcrumbCategoryKey}>{t(`incident.category.${breadcrumbCategoryKey}`)}</span>;
                        })}
                    </Title>
                </Stack>
            </StackStyled>
        </Box>
    );
};
