import { Breakpoints, Button, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";

import {
    Bottom,
    ButtonStyled,
    GetStartedButton,
    ImageTop,
    InsideGradient,
    ModalContainer,
    ModalContent,
    Navigation,
    NavigationButtonsContainer,
    Slide,
    StepIndicator,
    StepsIndicatorContainer,
    SubtitlesTextStyed,
    TitleTextStyled,
} from "./Onboarding.styles";
import { SLIDES } from "./OnboardingModal.constants";

interface OnboardingModalProps {
    onClose: () => void;
}

export const OnbardingModal = (props: OnboardingModalProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const { t } = useTranslation();
    const slides = SLIDES;
    const lastItemIndex = slides.length - 1;

    const NextSlide = () => {
        setSelectedItem((prev) => prev + 1);
    };

    return (
        <ModalContainer role="modal-wrapper">
            <ModalContent data-testid="modal-content">
                <Carousel showIndicators={false} showStatus={false} showThumbs={false} swipeable={false} showArrows={false} selectedItem={selectedItem}>
                    {slides.map((slide, index) => (
                        <Slide background={slide.backgroundImageSrc} backgroundPosition={slide.backgroundPosition} key={"slide" + index}>
                            <InsideGradient>
                                <ImageTop width={slide.graphicsWidth} src={isMobile ? slide.graphicsSrcMobile : slide.graphicsSrc} />
                            </InsideGradient>
                        </Slide>
                    ))}
                </Carousel>
                <Bottom data-testid="welcome-card-bottom">
                    <SubtitlesTextStyed>{t(slides[selectedItem].subheader)}</SubtitlesTextStyed>
                    <TitleTextStyled>{t(slides[selectedItem].header)}</TitleTextStyled>
                    <Navigation>
                        <StepsIndicatorContainer data-testid="steps-indicator-container">
                            {slides.map((_, index) => (
                                <StepIndicator
                                    data-testid="step-indicator"
                                    selected={selectedItem === index}
                                    key={"indicator" + index}
                                    onClick={() => setSelectedItem(index)}
                                />
                            ))}
                        </StepsIndicatorContainer>
                        <NavigationButtonsContainer data-testid="navigation-buttons-container">
                            {selectedItem < lastItemIndex ? (
                                <>
                                    <Button data-testid="next-button" onClick={NextSlide} mode="contained">
                                        {t("onboarding.next")}
                                    </Button>
                                    <ButtonStyled data-testid="skip-button" color="primary" onClick={props.onClose}>
                                        {t("onboarding.skip")}
                                    </ButtonStyled>
                                </>
                            ) : (
                                <GetStartedButton data-testid="get-started-button" mode="contained" onClick={props.onClose}>
                                    {t("onboarding.getStarted")}
                                </GetStartedButton>
                            )}
                        </NavigationButtonsContainer>
                    </Navigation>
                </Bottom>
            </ModalContent>
        </ModalContainer>
    );
};
