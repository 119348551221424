import { ButtonIcon, Icon } from "@secuis/ccp-react-components";
import { StackProps } from "@secuis/ccp-react-components/dist/types/styles/stack/Stack";
import { forwardRef, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { ContainerStyled, ContentWrapperStyled, DraggingAddonStyled, ToolBarStyled, TooltipStyled, WrapperStyled } from "./DraggableWidgetWrapper.styles";

type Props = PropsWithChildren<
    {
        editMode?: boolean;
        disabled?: boolean;
        dragged?: boolean;
    } & StackProps
>;

export const DraggableWidgetWrapper = forwardRef<HTMLDivElement, Props>(({ children, dragged, editMode, disabled, ...otherProps }, ref) => {
    const isDragged = editMode && dragged;
    const { t } = useTranslation();
    const tooltipAnchorClass = "dragged-widget";

    return (
        <ContainerStyled ref={ref} shaking={editMode && !(dragged || disabled)} {...otherProps}>
            <WrapperStyled editable={editMode} dragged={dragged} disabled={disabled}>
                {isDragged && (
                    <DraggingAddonStyled>
                        <TooltipStyled anchorSelect={`.${tooltipAnchorClass}`} isOpen={true}>
                            {t("common.draggableGrid.draggingTooltip")}
                        </TooltipStyled>
                        <Icon variant="Moving" className={tooltipAnchorClass} size="XXL" />
                    </DraggingAddonStyled>
                )}

                {editMode && (
                    <ToolBarStyled>
                        <ButtonIcon icon={disabled ? "Plus" : "Close"} iconSize="S" mode="stateless" />
                    </ToolBarStyled>
                )}

                <ContentWrapperStyled blurred={isDragged}>{children}</ContentWrapperStyled>
            </WrapperStyled>
        </ContainerStyled>
    );
});

DraggableWidgetWrapper.displayName = "DraggableWidgetWrapper";
