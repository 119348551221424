import { ReportsListItemType } from "src/models/ReportModel";

export const useTrueException = (
    report: ReportsListItemType,
): {
    trueExceptionCount: number;
} => {
    const propertiesMap = new Map(report.properties.map((p) => [p.key, p.value]));

    const isTrueException = propertiesMap.get("isTrueException") === "true";
    const trueExceptionCount = parseInt(propertiesMap.get("trueExceptionCount") || "0");

    return {
        trueExceptionCount: isTrueException || trueExceptionCount === 1 ? 1 : trueExceptionCount,
    };
};
