import { Breakpoints, Headline, Icon, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TextWithIcon } from "src/components/Reports/SharedComponents/TextWithIcon";
import { BackButton } from "src/components/shared/BackButton";
import LocationsSelectors from "src/store/locations/LocationsSelectors";

import { TitleWrapper } from "../Common.styled";
import { BarchartBreadcrumbs } from "./Barchart/BarchartBreadcrumbs";
import { SelectedCategories } from "./InsightsSitePage.types";

interface InsightsSitePageHeaderProps {
    selectedCategories: SelectedCategories;
    activeTab: number;
    levelChangeHandler: (selectedCategories: SelectedCategories) => void;
    siteId: string;
    InsightsDateFilter: ({ onCalendarOpen }: { onCalendarOpen?: () => void }) => JSX.Element;
    eventsTabIndex: number;
}

export const InsightsSitePageHeader = ({
    selectedCategories,
    activeTab,
    levelChangeHandler,
    siteId,
    InsightsDateFilter,
    eventsTabIndex,
}: InsightsSitePageHeaderProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();
    const locationDetails = useSelector(LocationsSelectors.getAuthorizedLocationById(siteId));

    return (
        <Stack direction="column" pt={isMobile ? "XS" : "M"} pb={isMobile ? "S" : "M"} ph="XS" style={{ width: "inherit" }}>
            {isMobile ? (
                <Stack direction="column" gap="XS">
                    {((!selectedCategories.level1 && activeTab === eventsTabIndex) || activeTab !== eventsTabIndex) && (
                        <BackButton defaultPath="/insights" defaultLabel={t("sites.insights")} />
                    )}
                    {selectedCategories.level1 && activeTab === eventsTabIndex && (
                        <BarchartBreadcrumbs
                            categoryLevel1={selectedCategories.level1}
                            categoryLevel2={selectedCategories.level2}
                            setBarchart={levelChangeHandler}
                        />
                    )}
                    {!selectedCategories.level1 && (
                        <>
                            <Stack gap={"XXS"} alignItems="center" pt="XS">
                                <Icon variant="BuildingFilled" />
                                <Headline bold>{locationDetails.name}</Headline>
                            </Stack>
                            <TextWithIcon id={siteId} color={"neutral"} iconSize={"M"} variant={"Location"}>
                                {locationDetails.address}
                            </TextWithIcon>
                        </>
                    )}
                    <InsightsDateFilter />
                </Stack>
            ) : (
                <>
                    <TitleWrapper>
                        <BackButton defaultPath="/insights" defaultLabel={t("sites.insights")} />
                        <InsightsDateFilter />
                    </TitleWrapper>
                    <Stack gap="S" alignItems="center" pt="XS">
                        <Stack gap={"XXS"} alignItems="center">
                            <Icon variant="BuildingFilled" />
                            <Headline bold>{locationDetails.name}</Headline>
                        </Stack>
                        <TextWithIcon id={siteId} color={"neutral"} iconSize={"M"} variant={"Location"}>
                            {locationDetails.address}
                        </TextWithIcon>
                    </Stack>
                </>
            )}
        </Stack>
    );
};
