import { Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Modal } from "src/components/shared/Modal/Modal";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { DraggableGrid } from "./DraggableGrid/DraggableGrid";
import { useSummaryViewMode } from "./shared/hooks/useSummaryViewMode";
import { GRID_WIDGETS } from "./Summary.constants";
import { useSummary, useSurvicateInSummary } from "./Summary.hooks";
import { SummaryGrid } from "./SummaryGrid";
import { SummaryHeader } from "./SummaryHeader";

export const Summary = () => {
    const {
        gridDefaultLayouts,
        isGridReady,
        isCustomized,
        isEditMode,
        isResetModalOpen,
        toggleResetModal,
        toggleEditMode,
        handleSettingsSave,
        handleSettingsReset,
        handleGridLayoutChange,
    } = useSummary();
    const { handleUserInteraction } = useSurvicateInSummary();
    const { t } = useTranslation();
    const viewMode = useSummaryViewMode();
    const isCustomizable = useFeatureFlag(featureFlags.personalizeKpiSummary);

    return (
        <div onMouseMove={handleUserInteraction}>
            <SummaryHeader
                isEditMode={isEditMode}
                isCustomized={isCustomized}
                toggleEditMode={toggleEditMode}
                onSettingsSave={handleSettingsSave}
                onSettingsReset={toggleResetModal}
            />
            {isCustomizable ? (
                isGridReady && (
                    <DraggableGrid
                        editable={isEditMode}
                        widgets={GRID_WIDGETS}
                        defaultLayouts={gridDefaultLayouts}
                        defaultLayoutConfig={GRID_WIDGETS}
                        onLayoutChange={handleGridLayoutChange}
                    />
                )
            ) : (
                <SummaryGrid />
            )}
            {isResetModalOpen && (
                <Modal
                    actions={[
                        {
                            children: t("insights.trendingEvents.resetModal.resetToDefault"),
                            onClick: handleSettingsReset,
                        },
                        {
                            children: t("common.cancel"),
                            onClick: toggleResetModal,
                        },
                    ]}
                    isOpen={isResetModalOpen}
                    onClose={toggleResetModal}
                    size="S"
                    title={t("insights.summary.settings.resetModal.title")}
                >
                    <Text>{t(`insights.summary.settings.resetModal.description.${viewMode}`)}</Text>
                </Modal>
            )}
        </div>
    );
};
