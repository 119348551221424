import { Breakpoints, Icon, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefaultItemField } from "src/components/Reports/DefaultItemField";
import { DynamicTemplateSection } from "src/components/shared/Section/DynamicTemplateSection";
import { formatDateTimeWithTimezone } from "src/helpers/date";

import { CheckpointScanResult, CheckpointScanType, PatrolTourType } from "../../../../../../models/ReportModel";
import { CheckpointSortComponent } from "./CheckpointSortComponent";

type Props = {
    report: PatrolTourType;
};

export const CheckpointData = ({ report }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [checkpointsData, setCheckpointsData] = useState([]);
    const { t } = useTranslation();
    const scannedCheckpoints = useMemo(() => report.checkpointScans.filter((c) => c.result === CheckpointScanResult.scanned), [report]);

    const onSortChange = (sortedCheckpoints) => {
        setCheckpointsData(sortedCheckpoints);
    };

    if (scannedCheckpoints.length < 1) {
        return null;
    }

    return (
        <DynamicTemplateSection
            label="reportdetails.patrolTour.checkpointDetails"
            collapsedLabel={t("reportdetails.summary.moreDetails")}
            expandedLabel={t("reportdetails.summary.lessDetails")}
            rightHeader={
                <CheckpointSortComponent
                    checkpointScans={[...report.checkpointScans]}
                    isSortButtonHidden={scannedCheckpoints.length === 1}
                    onSortChange={onSortChange}
                />
            }
        >
            {checkpointsData
                .filter((c) => c.result === CheckpointScanResult.scanned)
                .map((checkpoint: CheckpointScanType, index) => {
                    const dateLabel = formatDateTimeWithTimezone(checkpoint.scanDateTime, report.siteIanaTimezone);

                    return (
                        <DefaultItemField
                            key={`${checkpoint.checkpointId}${checkpoint.checkpointScanId}${index}`}
                            label={checkpoint.checkpointName}
                            direction={isMobile ? "column" : "row"}
                        >
                            <Stack justifyContent="space-between" gap="XL">
                                <Text>{dateLabel}</Text>
                                <Stack gap="XS">
                                    <Text>{t(`reportdetails.patrolTour.${checkpoint?.result?.toLowerCase()}`)}</Text>
                                    <Icon variant="Success" color="success" />
                                </Stack>
                            </Stack>
                        </DefaultItemField>
                    );
                })}
        </DynamicTemplateSection>
    );
};
