import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { TourStatus } from "src/components/shared/TourStatus";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { TourFields } from "src/models/reportTemplates/Tour";

import { ReportsListItemType, ReportTemplateEnum } from "../../../../../models/ReportModel";
import { ItemDetailsStyled } from "../ReportsListItem.styles";
import { CategoriesStack } from "./ListItemPatrolTourSummary.styled";
import { MissedCheckpoints } from "./MissedCheckpoints";
import { PercentageBadge } from "./SharedStyles";
import { TrueExceptionCount } from "./TrueExceptionCount";

export const ListItemPatrolTourSummary = ({ report }: { report: ReportsListItemType }) => {
    const { t } = useTranslation();
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    if (report.type !== ReportTemplateEnum.patrolTour) return null;

    const missed = +(report.properties.find((p) => p.key === TourFields.missedCheckpointsTotal)?.value ?? 0);
    const total = +(report.properties.find((p) => p.key === TourFields.checkpointsTotal)?.value ?? 0);
    const exceptionIdsValue = report.properties.find((p) => p.key === "exceptionIds")?.value ?? 0;
    const exceptionCount = exceptionIdsValue ? JSON.parse(exceptionIdsValue).length : 0;

    if (!total) {
        return null;
    }

    const tourCompletionPercentage = Math.round(((total - missed) / total) * 100);
    const tourCompletionText = isTrueDeviationsEnabled ? `${t("reportlist.tourCompletion")}: ` : "";
    const percentageLabel = `${tourCompletionText}${tourCompletionPercentage}%`;

    return (
        <ItemDetailsStyled>
            <Stack gap="XS" direction="row" flexWrap="wrap">
                <PercentageBadge label={percentageLabel} mode="outlined" uppercase={false} />
                <CategoriesStack direction="row" flexWrap="wrap">
                    {isTrueDeviationsEnabled ? (
                        <Stack gap="XS" direction={isMobile ? "column" : "row"}>
                            {exceptionCount > 0 && <TrueExceptionCount exceptionCount={exceptionCount} />}
                            {missed > 0 && <MissedCheckpoints count={missed} />}
                        </Stack>
                    ) : (
                        <>
                            <TourStatus color="medium">{`${exceptionCount} ${t("reportlist.deviations", { count: exceptionCount })}`}</TourStatus>
                            <TourStatus color="high">{`${missed} ${t("reportlist.missed")}`}</TourStatus>
                        </>
                    )}
                </CategoriesStack>
            </Stack>
        </ItemDetailsStyled>
    );
};
