import { Box } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { CalloutLog } from "src/models/ReportModel";

import { LegendItem, TimelineEvent } from "./components";
import { useTimeline } from "./Timeline.hooks";
import { LegendWrapper, StackStyled, TimelineWrapperStyled } from "./Timeline.styles";

type Props = {
    fieldValue: { logs: CalloutLog[] };
    timeZone?: string;
    shouldAutoTranslate?: boolean;
};

export const Timeline = ({ fieldValue, shouldAutoTranslate, timeZone }: Props) => {
    const { t } = useTranslation();
    const { timeOneSite, responseTime, timelineItems } = useTimeline(fieldValue, shouldAutoTranslate, timeZone);

    return (
        <TimelineWrapperStyled>
            <StackStyled>
                {timelineItems.length ? (
                    <>
                        <Box>
                            {timelineItems.map(({ dateTime, iconType, key, lineColor, enableAutoTranslate, status }) => {
                                return (
                                    <TimelineEvent
                                        key={key}
                                        iconType={iconType}
                                        status={status}
                                        dateTime={dateTime}
                                        lineColor={lineColor}
                                        shouldAutoTranslate={enableAutoTranslate}
                                    />
                                );
                            })}
                        </Box>
                        {responseTime || timeOneSite ? (
                            <LegendWrapper>
                                {responseTime && timeOneSite && <LegendItem label={t("reportdetails.callout.responseTime")} value={responseTime} />}
                                {timeOneSite && <LegendItem label={t("reportdetails.callout.timeOneSite")} value={timeOneSite} />}
                            </LegendWrapper>
                        ) : null}
                    </>
                ) : (
                    t("common.noDataFound")
                )}
            </StackStyled>
        </TimelineWrapperStyled>
    );
};
