import { Headline, Icon, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "src/components/shared/Tooltip";

export const PageHeadline = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="XS" mb="S" pt="S" alignItems="center">
            <Headline>{t("settings.severityLevels")}</Headline>
            <Tooltip message={t("settings.headlineTooltip.otherUsersText")}>
                <Icon variant="Info" data-testid="csl-headline-icon" />
            </Tooltip>
        </Stack>
    );
};
