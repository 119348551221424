import { Stack, Tab } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { useInsightsTabs } from "./InsightsTabs.hooks";
import { TabsStyled } from "./InsightsTabs.styles";
import { TabContent } from "./TabContent";

export const InsightsTabs = () => {
    const { t } = useTranslation();
    const { activeTab, setActiveTab, tabs, handleTabView } = useInsightsTabs();
    const { isFilterActive } = useInsightsPageContext();

    return (
        <Stack flex="1">
            {/*NOTE: Rerender tabs when activeTab is changed from outside of Tabs (like redux) */}
            <TabsStyled
                isFilterActive={isFilterActive}
                data-testid="insights-tabs"
                key={activeTab}
                value={activeTab}
                onChange={setActiveTab}
                onClick={handleTabView}
            >
                {tabs.map((tab, index) => {
                    const Page = tab.component;

                    return (
                        <Tab key={tab.labelKey} label={t(tab.labelKey)}>
                            {activeTab === index && (
                                <TabContent>
                                    <Page />
                                </TabContent>
                            )}
                        </Tab>
                    );
                })}
            </TabsStyled>
        </Stack>
    );
};
