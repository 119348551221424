import { formatDateTimeWithTimezone } from "src/helpers/date";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "src/models/DynamicTemplate";
import { PatrolTourType } from "src/models/ReportModel";

import { base64Assets } from "../../../PdfReports/Base64Assets";
import { CheckpointDataPdf } from "../../../PdfReports/CheckpointDataPdf";
import { PatrolSummaryPdf } from "../../../PdfReports/PatrolSummaryPdf";
import { DynamicTemplateSectionPdf } from "../../../PdfReports/shared/PdfDynamicTemplateSection";
import { PdfPatrolDetailsHeader } from "../../../PdfReports/shared/PdfPatrolDetailsHeader";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { DateRangeSummaryFieldPdf, TextAreaSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { PdfLogoHeader } from "../../../PdfReports/shared/PdfTemplateHeadline";
import { TourExceptionsOverviewPdf } from "../../../PdfReports/TourExceptionsOverviewPdf";
import { conditionalRender } from "../DynamicTemplateUtils";

export const getPatrolTourReportConfigPdf = ({
    report,
    siteObject,
    sharedReportId,
    user,
}: DynamicConfigPropsType<PatrolTourType>): DynamicComponentConfigType[] => [
    {
        component: PdfLogoHeader,
        props: {
            logo: `data:image/png;base64,${base64Assets.securitasLogo}`,
        },
    },
    {
        component: PdfPatrolDetailsHeader,
        props: { report, siteObject, isShared: !!sharedReportId, user },
    },
    {
        component: DynamicTemplateSectionPdf,
        props: {
            label: "reportdetails.patrolTour.checkpointCompletion",
            marginTop: "S",
        },
        children: [
            {
                component: PatrolSummaryPdf,
                props: {
                    report: report,
                },
            },
        ],
    },
    conditionalRender(!!report.incompleteReason, () => ({
        component: DynamicTemplateSectionPdf,
        props: {
            label: "reportdetails.tourException.comments",
            fullyTranslated: true,
        },
        children: [
            {
                component: TextAreaSummaryFieldPdf,
                props: {
                    fieldValue: report.incompleteReason,
                    shouldAutoTranslate: true,
                },
            },
        ],
    })),
    {
        component: TourExceptionsOverviewPdf,
        props: {
            report: report,
            sharedReportId: sharedReportId,
        },
    },
    {
        component: CheckpointDataPdf,
        props: {
            report: report,
        },
    },
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.patrolTour.summary",
            renderLineBreak: false,
        },
        children: [
            {
                component: DateRangeSummaryFieldPdf,
                props: {
                    fieldValue: formatDateTimeWithTimezone(report.startDateTime, report.siteIanaTimezone),
                    fieldValue2: formatDateTimeWithTimezone(report.endDateTime, report.siteIanaTimezone),
                    fieldName: "reportdetails.patrolTour.patrolDateTime",
                },
            },
        ],
    },
];
