import { nanoid } from "@reduxjs/toolkit";
import { LoadingIndicator } from "@secuis/ccp-react-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAutoTranslatedExceptions } from "src/helpers/autoTranslations";

import { DynamicTemplateSection } from "../../../../../../components/shared/Section/DynamicTemplateSection";
import { getExceptionQuestionsAnswers, getFieldValueByFieldName } from "../../../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { PatrolTourType, ReportTemplateEnum } from "../../../../../../models/ReportModel";
import { useRelatedReportsReady } from "../../../../../../store/reports/ReportsHooks";
import { ReportDisplayModal } from "./ReportDisplayModal";
import { TourExceptionField, TourMultiExceptionField } from "./TourExceptionField";

interface PropsType {
    report: PatrolTourType;
    siteObject: any;
    sharedReportId: string;
    shouldAutoTranslate?: boolean;
}

const isFirst = (index) => index === 0;
const isLast = (index, length) => index === length - 1;

export const TourExceptionsOverview = ({ report, siteObject, sharedReportId, shouldAutoTranslate }: PropsType) => {
    const { t } = useTranslation();
    const [exceptionReportModalVisible, setExceptionReportModalVisible] = useState(false);
    const [selectedReport, setSelectedReport] = useState(false);
    const [leftOffset, setLeftOffset] = useState<number>(0);
    const relatedReportReady = useRelatedReportsReady(report);

    const { translationsMap } = useAutoTranslatedExceptions(report, shouldAutoTranslate);

    const openCloseHandler = (selectedReport) => {
        setExceptionReportModalVisible((prev) => !prev);
        setSelectedReport(selectedReport);
    };

    const tourExceptions = useMemo(() => {
        const exceptionReports = { tourMultiException: [], tourException: [] };
        report.relatedReports?.map((relatedReport) => {
            const fieldValue = getFieldValueByFieldName("exceptionQuestions", relatedReport);

            if (relatedReport.template === ReportTemplateEnum.tourMultiException && fieldValue) {
                const questionsAnswers = getExceptionQuestionsAnswers(fieldValue)
                    .map(([question, answer]) => {
                        if (question) {
                            const trimmedQuestion = question.trim();

                            return {
                                question: translationsMap.get(trimmedQuestion) ?? trimmedQuestion,
                                answer: translationsMap.get(answer) ?? answer,
                                key: nanoid(),
                            };
                        }
                    })
                    .filter((x) => x !== undefined)
                    .sort((a, b) => a.question.localeCompare(b.question));

                exceptionReports.tourMultiException.push({
                    questionsAnswers,
                    report: relatedReport,
                });
            }
            if (relatedReport.template === ReportTemplateEnum.tourException && getFieldValueByFieldName("exceptionQuestion", relatedReport)) {
                const exceptionQuestion = getFieldValueByFieldName("exceptionQuestion", relatedReport);
                const exceptionAnswer = getFieldValueByFieldName("exceptionAnswer", relatedReport);

                exceptionReports.tourException.push({
                    report: relatedReport,
                    question: translationsMap[exceptionQuestion] ?? exceptionQuestion,
                    answer: translationsMap[exceptionAnswer] ?? exceptionAnswer,
                    key: nanoid(),
                });
            }
        });

        return exceptionReports;
    }, [report.relatedReports, translationsMap]);

    if (
        (!sharedReportId && !tourExceptions.tourMultiException.length && !tourExceptions.tourException.length) ||
        (sharedReportId && !report.exceptions.length)
    ) {
        return null;
    }

    return (
        <>
            <ReportDisplayModal
                isOpen={exceptionReportModalVisible}
                report={selectedReport}
                siteObject={siteObject}
                onClose={() => openCloseHandler(null)}
                isReportListDetails={leftOffset > 200}
            />
            <DynamicTemplateSection
                label={t("reportdetails.report.tourExceptions")}
                collapsedLabel={t("reportdetails.summary.moreDetails")}
                expandedLabel={t("reportdetails.summary.lessDetails")}
                setLeftOffsetHandler={setLeftOffset}
            >
                {sharedReportId ? (
                    report.exceptions.map((exceptionsList) =>
                        exceptionsList.exceptions
                            .slice()
                            .sort((a, b) => a.question.localeCompare(b.question))
                            .map((exception, index) => {
                                return (
                                    <TourMultiExceptionField
                                        label={exception.question}
                                        value={exception.answer}
                                        onClick={() => {}}
                                        disabled={true}
                                        isFirst={isFirst(index)}
                                        isLast={isLast(index, exceptionsList.exceptions.length)}
                                        key={`${exceptionsList.id}${index}`}
                                    />
                                );
                            }),
                    )
                ) : (
                    <>
                        {!relatedReportReady && <LoadingIndicator size="S" />}
                        {relatedReportReady && (
                            <>
                                {tourExceptions.tourMultiException.map((tme) => {
                                    return tme.questionsAnswers?.map((qa, index) => (
                                        <TourMultiExceptionField
                                            label={qa.question}
                                            value={qa.answer}
                                            isFirst={isFirst(index)}
                                            isLast={isLast(index, tme.questionsAnswers.length)}
                                            onClick={() => openCloseHandler(tme.report)}
                                            key={`${qa.key}tourMultiSection`}
                                        />
                                    ));
                                })}
                                {tourExceptions.tourException.map((te) => (
                                    <TourExceptionField label={te.question} value={te.answer} onClick={() => openCloseHandler(te.report)} key={te.key} />
                                ))}
                            </>
                        )}
                    </>
                )}
            </DynamicTemplateSection>
        </>
    );
};
