import { Survicate } from "@survicate/survicate-web-surveys-wrapper";
import { getSurvicateInstance, initSurvicate } from "@survicate/survicate-web-surveys-wrapper/widget_wrapper";
import { mapValues } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useAuthorizedLocations } from "../../store/locations/LocationsHooks";
import { useUserInfo } from "../../store/user/UserHooks";
import { SURVICATE_PLACEHOLDER } from "./constants";
import { getSurvicateTraits } from "./helpers";
import { RespondentTraits } from "./types";

export const useSurvicateProvider = (isAppReady: boolean) => {
    const workspaceKey = process.env.REACT_APP_SURVICATE_WORKSPACE_KEY;
    const traits = useSurvicateTraits();

    const [survicateInstance, setSurvicateInstance] = useState<Survicate | null>(null);
    const instance = useMemo(() => survicateInstance ?? SURVICATE_PLACEHOLDER, [survicateInstance]);

    useEffect(() => {
        const initialize = async () => {
            await initSurvicate({ workspaceKey, traits: mapValues(traits, (trait) => trait?.toString() ?? "") });
            const instance = getSurvicateInstance();
            setSurvicateInstance(instance);
        };

        if (workspaceKey && isAppReady && traits) {
            initialize();
        }
    }, [isAppReady, traits, workspaceKey]);

    return instance;
};

export const useSurvicateTraits = (): RespondentTraits => {
    const { info: userInfo } = useUserInfo();
    const { siteObjects } = useAuthorizedLocations();

    return useMemo(() => getSurvicateTraits(userInfo, siteObjects), [siteObjects, userInfo]);
};
