import { Breakpoints, EmptyState, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { BoxStyled } from "./EmptyStateSitePage.styles";

export const EmptyStateSitePage = () => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <BoxStyled ph={isMobile ? "S" : "L"} paddingTop="XL">
            <EmptyState subtitle={t("insights.patrolTours.noDataSubtitle")} title={t("insights.patrolTours.noDataTitle")} icon="GraphIndicator" />
        </BoxStyled>
    );
};
