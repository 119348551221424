import { Breakpoints, Icon, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const IconStyled = styled(Icon)`
    margin-right: ${Spacing.XS}px;
`;

export const StackStyled = styled(Stack)`
    span:not(:last-child)::after {
        content: "•";
        margin: 0 ${Spacing.XS}px;
    }
`;

export const BackButtonWrapperStyled = styled(Stack).attrs({
    mb: ["XL", "L", "S"],
})`
    @media (min-width: ${Breakpoints.XS}) and (max-width: 860px) {
        width: 200px;
        text-align: left;
    }
`;
