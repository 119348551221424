import { formatToRawDate } from "../../helpers/date";
import { GetByDateRange } from "./InsightsModel";

export const parseDateRangeRequestBody = (body: GetByDateRange & Record<string, unknown>) => {
    const { fromDateTime, toDateTime, ...payload } = body;

    return {
        ...payload,
        fromDate: formatToRawDate(fromDateTime),
        toDate: formatToRawDate(toDateTime),
    };
};
