import { useCallback, useEffect, useState } from "react";
import { Databases } from "src/models/DbModel";
import queries from "src/sql/scripts/insights/trendingEventQueries";
import { useDatabase } from "src/store/insights/InsightsLoadHooks";
import { ITrendingEvent } from "src/store/insights/InsightsModel";

import { CustomizedCategories } from "../TrendingEvents.types";

export const useFetchDbEvents = (
    siteIds: string[],
    selectedCategories: CustomizedCategories,
    monthsBack: number,
): {
    isLoading: boolean;
    events: ITrendingEvent[];
} => {
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const [isLoaded, setIsLoaded] = useState(false);
    const [events, setEvents] = useState<ITrendingEvent[]>([]);

    useEffect(() => {
        setIsLoaded(false);
    }, [siteIds, selectedCategories]);

    const queryTrendingEvents = useCallback(
        async (locationIds: string[], queryCategories: CustomizedCategories) => {
            try {
                const trendingEventsQuery = queries.trendingEvents.query(locationIds, queryCategories, monthsBack);
                const trendingEventsJob = execute(trendingEventsQuery.sql, trendingEventsQuery.params);
                const queryResults = await Promise.all([trendingEventsJob]);

                return queries.trendingEvents.getResults(queryResults[0]?.results, queryCategories);
            } finally {
                setIsLoaded(true);
            }
        },
        [execute, monthsBack],
    );

    useEffect(() => {
        const getTrendingEvents = async () => {
            const data = await queryTrendingEvents(siteIds, selectedCategories);
            setEvents(data);
        };
        if (!dbReadyToQuery || isLoaded) {
            return;
        }
        getTrendingEvents();
    }, [dbReadyToQuery, isLoaded, siteIds, queryTrendingEvents, selectedCategories]);

    return { isLoading: !isLoaded || !dbReadyToQuery, events };
};
