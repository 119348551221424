import { Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const IconStyled = styled(Icon)`
    margin-right: 4px;
`;

type Props = {
    exceptionCount: number;
};

export const TrueExceptionCount = ({ exceptionCount }: Props) => {
    const { t } = useTranslation();

    return exceptionCount === 1 ? (
        <Stack alignItems="center">
            <IconStyled size="S" variant="Deviations" />
            <Text micro>{t("reportlist.deviations")}</Text>
        </Stack>
    ) : (
        <Stack alignItems="center">
            <IconStyled size="S" variant="Deviations" />
            <Text micro>
                {exceptionCount} {t("reportlist.deviations_plural")}
            </Text>
        </Stack>
    );
};
