import { RSAA } from "redux-api-middleware";

import { parseDateRangeRequestBody } from "../helpers";
import { GetByDateRange } from "../InsightsModel";
import {
    INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_FAILURE,
    INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_REQUEST,
    INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_SUCCESS,
    INSIGHTS_LOCATION_TOURS_FAILURE,
    INSIGHTS_LOCATION_TOURS_REQUEST,
    INSIGHTS_LOCATION_TOURS_SUCCESS,
} from "../types";

export const requestLocationToursComparePeriod = (locationId: string, body: GetByDateRange) => {
    return {
        [RSAA]: {
            auth: true,
            endpoint: `/v2/global/insights/tour-sessions/${locationId}/filter`,
            method: "POST",
            body: parseDateRangeRequestBody(body),
            types: [
                { type: INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_REQUEST },
                { type: INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_SUCCESS },
                { type: INSIGHTS_LOCATION_TOURS_COMPARE_PERIOD_FAILURE },
            ],
        },
    };
};

export const requestLocationTours = (): {
    type: string;
} => ({
    type: INSIGHTS_LOCATION_TOURS_REQUEST,
});

export const locationToursSuccess = (
    result: any[],
    queryKey: string,
): {
    type: string;
    payload: any[];
    queryKey: string;
} => ({
    type: INSIGHTS_LOCATION_TOURS_SUCCESS,
    payload: result,
    queryKey,
});

export const locationToursFailue = (): {
    type: string;
} => ({
    type: INSIGHTS_LOCATION_TOURS_FAILURE,
});
