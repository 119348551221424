import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SCHEDULE_INITIAL_STATE } from "./constants";

export const scheduleSlice = createSlice({
    name: "schedule",
    initialState: SCHEDULE_INITIAL_STATE,
    reducers: {
        changeDate: (state, action: PayloadAction<Date>) => {
            state.selectedDate = action.payload;
        },
    },
});
