import { Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const UlStyled = styled.ul`
    margin: 0;
    padding-inline-start: ${Spacing.M}px;
`;

export const ContainerStyled = styled.div`
    // NOTE: Workaround for WelcomeCard tests that fails for unknown reason when its z-index is higher
    // Overriding default modal's overlay z-index;
    & > * {
        z-index: 90;
    }
`;
