import { Box, Icon, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const TextStyled = styled(Text)`
    margin-right: ${Spacing.XS}px;
    display: inline;
`;

export const TrueDeviationsDisclaimer = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="XS" mb="S">
            <Icon color="secondary" variant="Deviations" />
            <Box>
                <TextStyled small color="secondary">
                    {t("reportdetails.detailedInfo.trueDeviationsDisclaimer")}
                </TextStyled>
            </Box>
        </Stack>
    );
};
