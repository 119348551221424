export enum TaskScheduleStatus {
    scheduled = "Scheduled",
    inProgress = "InProgress",
    completed = "Completed",
    missed = "Missed",
    cancelled = "Cancelled",
}

export enum ScheduleType {
    recurring = "Recurring",
    oneTime = "OneTime",
}

export type TaskSchedule = {
    taskId: string;
    description: string;
    taskEventId: string;
    taskType: string;
    status: TaskScheduleStatus;
    StartDateTime: string;
    expectedDuration: string;
    position: { id: string; name: string };
    scheduleType: ScheduleType;
    tourSessionId: string;
    assignedTo?: {
        id: string;
        name: string;
    };
};

export interface IScheduleState {
    selectedDate: Date;
}
