import { Text } from "@secuis/ccp-react-components";
import { compactNumber } from "src/helpers/NumberHelper";

import { DataCellStyled } from "./DataCell.styles";

export type DataCellProps = {
    categoryIncidentCount: number;
    maxIncidentCount: number;
    onClick?: () => void;
    disabled?: boolean;
};

export const DataCell = ({ categoryIncidentCount, maxIncidentCount, onClick, disabled = false }: DataCellProps) => {
    return (
        <DataCellStyled categoryIncidentCount={categoryIncidentCount} maxIncidentCount={maxIncidentCount} disabled={disabled} onClick={onClick}>
            <Text data-testid="matrix-table-data-cell-count">{compactNumber(categoryIncidentCount)}</Text>
        </DataCellStyled>
    );
};
