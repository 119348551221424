import { WorkerExecResult } from "src/models/DbModel";
import { generateStringForQuery } from "src/sql/scripts/queryUtilities";

import { getReportQueryTimeRangeCondition } from "../../../../sql/scripts/queryUtilities";
import { EVENTS_OVERVIEW_REPORTS_TYPES } from "../../shared/constants";
import { CategoryIncidentCountPerSite } from "../../shared/types";

type FetchIncidentsQueryProps = {
    startDate: Date;
    endDate: Date;
    siteIds: string[];
    categories: string[];
    level: number;
};

const LOCATION_ID_COLUMN_NAME = "location_id";

export const fetchIncidentsQuery = ({ startDate, endDate, siteIds, categories, level }: FetchIncidentsQueryProps) => {
    return {
        sql: `
            SELECT
                ${LOCATION_ID_COLUMN_NAME},
                ${categories.map((category) => `COUNT(CASE WHEN category_level${level} = '${category}' THEN 1 END) AS ${category}`).join(", ")}
            FROM reports
            WHERE ${getReportQueryTimeRangeCondition(startDate, endDate)}
            AND ${LOCATION_ID_COLUMN_NAME} IN (${generateStringForQuery(siteIds)})
            AND template IN (${generateStringForQuery(EVENTS_OVERVIEW_REPORTS_TYPES)})
            GROUP BY ${LOCATION_ID_COLUMN_NAME}
        `,
        params: {},
    };
};

export const parseIncidentsQueryResult = ({ results }: WorkerExecResult): CategoryIncidentCountPerSite => {
    let parsedData: CategoryIncidentCountPerSite = {};

    if (results?.[0]?.values?.length) {
        const { columns, values } = results[0];
        const locationIdIndex = columns.indexOf(LOCATION_ID_COLUMN_NAME);
        const categories = columns.filter((columnName) => columnName !== LOCATION_ID_COLUMN_NAME);

        parsedData = values.reduce((siteCategoriesMap, valueRow) => {
            const locationId = valueRow[locationIdIndex];

            siteCategoriesMap[locationId] = categories.reduce(
                (categoryAcc, category) => {
                    const categoryIndex = columns.indexOf(category);
                    categoryAcc[category] = valueRow[categoryIndex];
                    return categoryAcc;
                },
                {} satisfies Record<string, number>,
            );

            return siteCategoriesMap;
        }, parsedData);
    }

    return parsedData;
};

export const getIncidentCountQuery = ({
    startDate,
    endDate,
    categories,
    level,
    siteIds,
}: {
    startDate: Date;
    endDate: Date;
    categories: string[];
    level: number;
    siteIds: string[];
}) => {
    return {
        sql: `
            SELECT
                COUNT(*) AS total_incidents
            FROM reports
            WHERE
                ${getReportQueryTimeRangeCondition(startDate, endDate)}
                AND ${LOCATION_ID_COLUMN_NAME} IN (${generateStringForQuery(siteIds)})
                AND category_level${level} IN (${generateStringForQuery(categories)})
                AND template IN (${generateStringForQuery(EVENTS_OVERVIEW_REPORTS_TYPES)})
        `,
        params: {},
    };
};

export const parseIncidentCountQuery = ({ results }: WorkerExecResult): { totalIncidents: number } => {
    return {
        totalIncidents: results?.[0]?.values?.[0]?.[0] ?? 0,
    };
};
