import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLazyGetTranslatedTextQuery } from "src/store/reports/reportsApi";

import { PatrolTourType, ReportTemplateEnum } from "../../models/ReportModel";
import ReportsSelectors from "../../store/reports/ReportsSelectors";
import { useUserInfo } from "../../store/user/UserHooks";
import { getExceptionQuestionsAnswers, getFieldValueByFieldName } from "../reportTemplates/dynamicTemplatePropertiesHelper";
import { useAutoTranslationsActions, useAutoTranslationsState } from "./AutoTranslationProvider";

export const useAutoTranslatedTexts = (texts: string[], shouldTranslate = true) => {
    const { info: authorizedUser } = useUserInfo();
    const { i18n } = useTranslation();
    const dynamicTranslations = useSelector(ReportsSelectors.getDynamicTranslations);
    const loading = texts?.some((text) => dynamicTranslations[text]?.loading);
    const [getTranslatedText] = useLazyGetTranslatedTextQuery();
    const { markAsAutoTranslated } = useAutoTranslationsActions();
    const { isAutoTranslationsEnabled, isAutoTranslationsActive } = useAutoTranslationsState();
    const isTranslatingEnabled = shouldTranslate && isAutoTranslationsEnabled;

    const translatedContent = useMemo(() => {
        const translationsMap = new Map<string, string>();
        const translations: string[] = [];

        texts?.forEach((text) => {
            if (isTranslatingEnabled && text && dynamicTranslations[text]) {
                const { translation } = dynamicTranslations[text];

                if (translation && translation.toLowerCase() !== text.toLowerCase()) {
                    markAsAutoTranslated();
                }

                const valueToStore = (isAutoTranslationsActive && translation) || text;
                translationsMap.set(text, valueToStore);
                translations.push(valueToStore);
            } else {
                translationsMap.set(text, text);
                translations.push(text);
            }
        });

        return { translationsMap, translations };
    }, [texts, dynamicTranslations, isTranslatingEnabled, isAutoTranslationsActive, markAsAutoTranslated]);

    useEffect(() => {
        if (!authorizedUser || !isTranslatingEnabled) return;

        texts?.forEach((text) => {
            if (text && !loading && !dynamicTranslations[text]) {
                getTranslatedText({ text, targetLanguage: i18n.language });
            }
        });
    }, [getTranslatedText, dynamicTranslations, texts, i18n.language, loading, authorizedUser, isTranslatingEnabled]);

    return { ...translatedContent, loading };
};

export const useAutoTranslatedText = (text: string, shouldTranslate = true) => {
    const {
        translations: [translatedText],
        loading,
    } = useAutoTranslatedTexts([text], shouldTranslate);

    return { translatedText, loading };
};

export const useAutoTranslationWhenNotTranslated = <T>(valueToTranslate: T, shouldTranslate = true) => {
    const { t, i18n } = useTranslation();

    const isAlreadyTranslated = typeof valueToTranslate === "string" && i18n.exists(valueToTranslate);
    const canAutoTranslate = !isAlreadyTranslated && shouldTranslate;

    const { translatedText: autoTranslatedValue, ...rest } = useAutoTranslatedText(valueToTranslate?.toString() || "", canAutoTranslate);

    return { translatedText: isAlreadyTranslated ? t(valueToTranslate) : autoTranslatedValue, ...rest };
};

export const useAutoTranslatedExceptions = (report: PatrolTourType, shouldAutoTranslate = true) => {
    const translationFields = report.relatedReports
        ?.flatMap((relatedReport) => {
            if (relatedReport.template === ReportTemplateEnum.tourMultiException && getFieldValueByFieldName("exceptionQuestions", relatedReport)) {
                return getExceptionQuestionsAnswers(getFieldValueByFieldName("exceptionQuestions", relatedReport)).flatMap(([question, answer]) => {
                    if (question) return [question.trim(), answer];
                });
            }
            if (relatedReport.template === ReportTemplateEnum.tourException) {
                return [getFieldValueByFieldName("exceptionQuestion", relatedReport), getFieldValueByFieldName("exceptionAnswer", relatedReport)];
            }
        })
        .filter((x) => x);

    return useAutoTranslatedTexts(translationFields, shouldAutoTranslate);
};
