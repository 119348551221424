import { useSummaryGrid } from "./SummaryGrid.hooks";
import { SummaryGridStyled } from "./SummaryGrid.styles";

export const SummaryGrid = () => {
    const { widgetsConfig } = useSummaryGrid();

    return (
        <SummaryGridStyled data-testid="widget-grid">
            {widgetsConfig.map(({ key, Widget, style }) => (
                <Widget key={key} data-testid={`${key}-widget`} style={style} />
            ))}
        </SummaryGridStyled>
    );
};
