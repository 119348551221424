import { View } from "@react-pdf/renderer";
import { nanoid } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getExceptionQuestionsAnswers, getFieldValueByFieldName } from "../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { PatrolTourType, ReportTemplateEnum } from "../../models/ReportModel";
import { styles } from "./pdfStyles";
import { DynamicTemplateSectionPdf } from "./shared/PdfDynamicTemplateSection";
import { TextSummaryFieldPdf } from "./shared/PdfSummarySections";

interface PropsType {
    report: PatrolTourType;
    sharedReportId: string;
}

export const TourExceptionsOverviewPdf = ({ report, sharedReportId }: PropsType) => {
    const { t } = useTranslation();

    const tourExceptions = useMemo(() => {
        const exceptionReports = { tourMultiException: [], tourException: [] };
        report.relatedReports?.map((rr) => {
            if (rr.template === ReportTemplateEnum.tourMultiException && getFieldValueByFieldName("exceptionQuestions", rr)) {
                const questionsAnswers = getExceptionQuestionsAnswers(getFieldValueByFieldName("exceptionQuestions", rr)).map((questionAnswerLine) => {
                    if (questionAnswerLine[0]) return { question: questionAnswerLine[0].trim(), answer: questionAnswerLine[1], key: nanoid() };
                });
                exceptionReports.tourMultiException.push({
                    questionsAnswers: questionsAnswers.filter((x) => x !== undefined).sort((a, b) => a.question.localeCompare(b.question)),
                    report: rr,
                });
            }
            if (rr.template === ReportTemplateEnum.tourException && getFieldValueByFieldName("exceptionQuestion", rr)) {
                exceptionReports.tourException.push({
                    report: rr,
                    question: getFieldValueByFieldName("exceptionQuestion", rr),
                    answer: getFieldValueByFieldName("exceptionAnswer", rr),
                    key: nanoid(),
                });
            }
        });

        return exceptionReports;
    }, [report.relatedReports]);

    const hasExceptionsInSharedReport = sharedReportId && !report.exceptions.length;
    const hasExceptionsInNonSharedReport = !sharedReportId && !tourExceptions.tourMultiException.length && !tourExceptions.tourException.length;

    if (hasExceptionsInSharedReport || hasExceptionsInNonSharedReport) {
        return null;
    }

    return (
        <DynamicTemplateSectionPdf label={t("reportdetails.report.tourExceptions")} renderLineBreak={false} marginTop="S">
            {sharedReportId ? (
                report.exceptions.map((exceptionsList) =>
                    exceptionsList.exceptions
                        .slice()
                        .sort((a, b) => a.question.localeCompare(b.question))
                        .map((exception, index) => (
                            <>
                                <TextSummaryFieldPdf
                                    key={`${exceptionsList.id}${index}sharedTourExceptionPdf`}
                                    fieldName={exception.question}
                                    fieldValue={exception.answer}
                                />
                                <View style={index == exceptionsList.length - 1 ? { ...styles.lineBreak, marginBottom: 0 } : styles.lineBreak} />
                            </>
                        )),
                )
            ) : (
                <>
                    {tourExceptions.tourMultiException.map((tme) => {
                        return tme.questionsAnswers?.map((qa, index) => (
                            <>
                                <TextSummaryFieldPdf key={`${qa.key}tourMultiSectionPdf`} fieldName={qa.question} fieldValue={qa.answer} />
                                <View
                                    style={
                                        index == tme.questionsAnswers.length - 1 && tourExceptions.tourException.length
                                            ? { ...styles.lineBreak, marginBottom: 0 }
                                            : styles.lineBreak
                                    }
                                />
                            </>
                        ));
                    })}
                    {tourExceptions.tourException.map((qa, index) => (
                        <>
                            <TextSummaryFieldPdf key={`${qa.key}tourExceptionSectionPdf`} fieldName={qa.question} fieldValue={qa.answer} />
                            <View style={index == tourExceptions.tourException.length - 1 ? { ...styles.lineBreak, marginBottom: 0 } : styles.lineBreak} />
                        </>
                    ))}
                </>
            )}
        </DynamicTemplateSectionPdf>
    );
};
