import type { SummarySettingsForViewMode } from "./Summary.types";

export const serializeSettings = (settings: SummarySettingsForViewMode): string => {
    return JSON.stringify(settings);
};

export const deserializeSettings = (data?: string): SummarySettingsForViewMode | undefined => {
    if (!data) {
        return undefined;
    }

    try {
        return JSON.parse(data) as SummarySettingsForViewMode;
    } catch (e) {
        console.error("Failed to parse settings", e);
    }
};
