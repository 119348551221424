import { Breakpoints, Button, ButtonText, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../styles/templates/Flex";

export const Intro = styled.div<{ opaque: boolean }>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #011523;
    z-index: 100;
    opacity: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    -webkit-transition:
        opacity 2s,
        z-index 2s 2s;
    -moz-transition:
        opacity 2s,
        z-index 2s 2s;
    transition:
        opacity 2s,
        z-index 2s 2s;

    ${({ opaque }) => (opaque ? "opacity: 0; z-index: 0;" : "opacity: 1; z-index: 100;")};
`;

export const Bottom = styled.div`
    text-align: center;
    height: 43%;
    @media (max-width: ${Breakpoints.XS}) {
        height: 50%;
    }
`;

export const NavigationButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 216px;
    margin: ${Spacing.S}px auto auto auto;

    @media (max-width: ${Breakpoints.XS}) {
        bottom: ${Spacing.XS}px;
    }

    @media (max-height: 540px) {
        margin: ${Spacing.XXS}px auto auto auto;
    }
`;

export const TitleTextStyled = styled(Text)`
    font-size: 32px;
    line-height: 38px;
    margin: ${Spacing.S}px ${Spacing.XS}px 0 ${Spacing.XS}px;

    @media (max-width: ${Breakpoints.XS}) {
        font-size: 18px;
        line-height: 27px;
    }

    @media (max-height: 640px) {
        margin-top: ${Spacing.XS}px;
    }

    @media (max-height: 540px) {
        margin-top: 0;
    }
`;

export const SubtitlesTextStyed = styled(Text)`
    margin: ${Spacing.S}px ${Spacing.XS}px 0 ${Spacing.XS}px;
    height: 1.5rem;

    @media (max-height: 640px) {
        margin-top: ${Spacing.XS}px;
    }

    @media (max-height: 540px) {
        margin-top: ${Spacing.XXS}px;
    }
`;

export const ButtonStyled = styled(ButtonText)`
    margin-top: ${Spacing.XS}px;
    justify-content: center;
`;

export const ModalContainer = styled.div`
    z-index: 99;
    position: fixed;
    overflow-y: hidden;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
`;

export const ModalContent = styled.div`
    ${flexColumn};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    background-color: ${Palette.Navy700};
    border-radius: ${Spacing.XS}px;
    width: 1000px;
    height: 90vh;
    max-height: 700px;

    .carousel-root {
        height: 57%;
    }

    .carousel-slider,
    .slider-wrapper {
        height: 100%;
        > ul {
            height: 100%;
            > li {
                height: 100%;
            }
        }
    }

    @media (max-width: ${Breakpoints.XS}) {
        width: 92%;
        .carousel-root {
            height: 50%;
        }
    }
`;

export const InsideGradient = styled.div`
    width: 40%;
    height: 100%;
    background: linear-gradient(270deg, rgba(35, 60, 89, 0.9) 32.31%, rgba(35, 60, 89, 0) 100%);
    right: 0;
    position: absolute;
    border-top-right-radius: ${Spacing.XS}px;
    display: flex;
    align-items: center;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        justify-content: center;
    }
`;

export const ImageTop = styled.img<{ width: string }>`
    right: ${Spacing.L}px;
    position: absolute;
    width: ${({ width }) => width}!important;

    @media (max-width: ${Breakpoints.XS}) {
        height: 90%;
        right: auto;
        max-width: 90%;
        object-fit: contain;
    }
`;

export const StepsIndicatorContainer = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: ${Spacing.XL}px;
`;

export const StepIndicator = styled.li<{ selected?: boolean }>`
    border: 1px solid ${Palette.Purple300};
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: ${Spacing.XS}px;
    margin: ${Spacing.XXS}px;
    width: ${Spacing.XS}px;
    ${({ selected }) => selected && `background-color: ${Palette.Purple300};`}
`;

export const Slide = styled.div<{ background: string; backgroundPosition?: string }>`
    height: 100%;
    overflow: hidden;
    background: url(${({ background }) => background});
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: ${Spacing.XS}px;
    border-top-right-radius: ${Spacing.XS}px;
    ${({ backgroundPosition }) => backgroundPosition && `background-position: ${backgroundPosition}`};
`;

export const Navigation = styled.div`
    @media (max-width: ${Breakpoints.XS}) {
        bottom: ${Spacing.S}px;
        position: absolute;
        align-content: center;
    }
    width: 100%;
`;

export const GetStartedButton = styled(Button)`
    @media (max-width: ${Breakpoints.XS}) {
        margin-bottom: calc(1.3125rem + 8px);
    }
`;
