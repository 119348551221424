import { Breakpoints } from "@secuis/ccp-react-components";
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";

import { MobileBreakpoints } from "../styles/Constants";
import { useIsDevice } from "./CommonHooks";

export const useIsInViewport = (ref: MutableRefObject<Element | null>, delay: number = 0): boolean => {
    const [isInViewport, setIsInViewport] = useState(false);
    const isMobileOrTablet = useIsDevice(Breakpoints.XS, MobileBreakpoints.LandscapeHeight);
    const refCurrent = ref?.current;

    const options: IntersectionObserverInit = useMemo(() => {
        return { threshold: isMobileOrTablet ? 0.5 : 0.8 };
    }, [isMobileOrTablet]);

    const callbackFn = useCallback((entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        setIsInViewport(entry.isIntersecting);
    }, []);

    useEffect(() => {
        if (!refCurrent) {
            return;
        }

        const observer = new IntersectionObserver(callbackFn, options);

        const startObserving = () => {
            observer.observe(refCurrent);
        };

        let timeoutId: NodeJS.Timeout | undefined;

        if (delay > 0) {
            timeoutId = setTimeout(startObserving, delay);
        } else {
            startObserving();
        }

        return () => {
            if (refCurrent) {
                observer.disconnect();
            }

            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [delay, options, refCurrent, callbackFn]);

    return isInViewport;
};
