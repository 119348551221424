import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useReport } from "src/components/Reports/ReportProvider";
import { featureFlags } from "src/data/featureFlags";
import { createContext } from "src/helpers/createContext";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import { UserPreference } from "src/models/UserModel";
import AppActions from "src/store/app/AppActions";
import ReportsSelectors from "src/store/reports/ReportsSelectors";
import { reportsSlice } from "src/store/reports/reportsSlice";
import TrackingActions from "src/store/tracking/TrackingActions";
import { useSetUserPreference, useUserPreference } from "src/store/user/UserHooks";

type AutoTranslationState = {
    isAutoTranslationsEnabled: boolean;
    isAutoTranslationsActive: boolean;
    isAutoTranslated: boolean;
};

type AutoTranslationActions = {
    toggle: () => void;
    markAsAutoTranslated: () => void;
};

const [AutoTranslationStateContext, useAutoTranslationsState] = createContext<AutoTranslationState>({
    defaultValue: {
        isAutoTranslationsEnabled: false,
        isAutoTranslationsActive: false,
        isAutoTranslated: false,
    },
    providerName: "AutoTranslation",
});

const [AutoTranslationActionsContext, useAutoTranslationsActions] = createContext<AutoTranslationActions>({
    defaultValue: {
        toggle: () => {},
        markAsAutoTranslated: () => {},
    },
    providerName: "AutoTranslationActions",
});

type Props = PropsWithChildren & {
    id?: string;
};

export const AutoTranslationProvider = ({ children, id }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const report = useReport();
    const setUserPreference = useSetUserPreference();
    const [isReportsAutoTranslationActive] = useUserPreference(UserPreference.IsReportsAutoTranslationActive);
    const isAutoTranslationsActive = isReportsAutoTranslationActive ?? true;
    const isAutoTranslationsEnabled = useBooleanFlagValue(featureFlags.autotranslations, false);
    const scopeSectionId = useMemo(() => `${report.id}_${id?.toLowerCase() || ""}`.replace(" ", "_"), [report, id]);
    const autoTranslationsSectionState = useSelector((state) => ReportsSelectors.getAutoTranslationsSectionState(state, scopeSectionId));
    const { isAnythingAutoTranslated } = autoTranslationsSectionState ?? {};
    const [isAutoTranslated, setIsAutoTranslated] = useState<boolean>(!!isAnythingAutoTranslated);

    useEffect(() => {
        if (isAutoTranslated && !isAnythingAutoTranslated) {
            dispatch(reportsSlice.actions.setReportAutotranslationsSection({ sectionId: scopeSectionId, isAnythingAutoTranslated: true }));
        }
    }, [scopeSectionId, isAutoTranslated, isAnythingAutoTranslated, dispatch]);

    const markAsAutoTranslated = useCallback(() => setIsAutoTranslated(true), [setIsAutoTranslated]);

    const toggle = useCallback(() => {
        const newIsActive = !isAutoTranslationsActive;
        setUserPreference(UserPreference.IsReportsAutoTranslationActive, newIsActive);

        dispatch(TrackingActions.trackEvent(newIsActive ? MixpanelEvent.ReportsAutoTranslateEnable : MixpanelEvent.ReportsAutoTranslateDisable));

        dispatch(
            AppActions.addInAppNotification({
                title: newIsActive ? t("autoTranslation.isEnabled") : t("autoTranslation.isDisabled"),
                type: "success",
            }),
        );
    }, [dispatch, isAutoTranslationsActive, setUserPreference, t]);

    const state = useMemo(
        () => ({
            isAutoTranslationsEnabled: isAutoTranslationsEnabled,
            isAutoTranslationsActive: isAutoTranslationsEnabled && isAutoTranslationsActive,
            isAutoTranslated,
        }),
        [isAutoTranslationsEnabled, isAutoTranslationsActive, isAutoTranslated],
    );

    const actions = useMemo(
        (): AutoTranslationActions => ({
            toggle,
            markAsAutoTranslated,
        }),
        [toggle, markAsAutoTranslated],
    );

    return (
        <AutoTranslationActionsContext.Provider value={actions}>
            <AutoTranslationStateContext.Provider value={state}>{children}</AutoTranslationStateContext.Provider>
        </AutoTranslationActionsContext.Provider>
    );
};

export { useAutoTranslationsState, useAutoTranslationsActions };
