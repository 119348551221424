import { getFieldValueByFieldName } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { ActivityLogFields } from "../../../../models/reportTemplates/ActivityLog";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { shouldRenderActivitiesSummary } from "../activityLogReportConfig";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getActivityLogReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.activities",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    description: getFieldValueByFieldName(ActivityLogFields.comments, report),
                    shouldAutoTranslate: true,
                    images,
                },
            },
        ],
    },
    conditionalRender(shouldRenderActivitiesSummary(report, sharedReportId), () => {
        return {
            component: SummarySectionPdf,
            props: {
                label: "reportdetails.detailedInfo.activitiesSummary",
            },
            children: [
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.wasTheActivityLoggedIntoTheJournal",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.wasTheActivityLoggedIntoTheJournal, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextAreaSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.additionalDescription",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.additionalDescription, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.debriefOfColleagueHappened",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.debriefOfColleagueHappened, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.passDownReportCompleted",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.passDownReportCompleted, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextAreaSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.anyOpenItemsToBeAddressed",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.anyOpenItemsToBeAddressed, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.commanderPresenceOnSite",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.commanderPresenceOnSite, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.nameOfCustomer",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.nameOfCustomer, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.job",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.activityLogJob, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: "reportdetails.activityLog.jobId",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.activityLogJobId, report),
                        isRequired: false,
                    },
                },
                ...getPdfUnknownFields(report, Object.values(ActivityLogFields), images),
            ],
        };
    }),
];
