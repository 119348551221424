import { Background, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

import { TooltipRich } from "../../../../../../components/shared/TooltipRich";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const shakingAnimation = css`
    @keyframes jiggle1 {
        0% {
            transform: rotate(-0.25deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(0.5deg);
            animation-timing-function: ease-out;
        }
    }

    @keyframes jiggle2 {
        0% {
            transform: rotate(0.25deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(-0.5deg);
            animation-timing-function: ease-out;
        }
    }

    &:nth-child(2n) > * {
        animation: jiggle1 250ms ease infinite alternate;
        transform-origin: 50% 3%;
    }

    &:nth-child(2n-1) > * {
        transform-origin: 40% 50%;
        animation: jiggle2 300ms ease infinite alternate;
    }
`;

export const ContainerStyled = styled.div<{
    shaking?: boolean;
}>`
    ${({ shaking }) => (shaking ? shakingAnimation : "")};
`;

export const WrapperStyled = styled(Stack).attrs({
    direction: "column",
})<{
    disabled?: boolean;
    dragged?: boolean;
    editable?: boolean;
}>`
    background: ${Background.content};
    position: relative;
    border-radius: ${Spacing.XXS}px;
    width: 100%;
    height: 100%;

    ${({ editable }) => (editable ? "cursor: grab" : "")};
    ${({ editable, dragged }) => (editable && dragged ? "cursor: grabbing" : "")};
    ${({ editable, disabled }) => (editable && disabled ? "cursor: pointer" : "")};

    &::after {
        ${({ editable }) => (editable ? `content: "";` : "")}
        position: absolute;
        border-radius: inherit;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
        background-color: ${({ disabled }) => (disabled ? Background.disabled : "transparent")};
        opacity: 0.6;
    }
`;

export const ToolBarStyled = styled(Stack).attrs({
    mh: "S",
    mt: "S",
    justifyContent: "flex-end",
})`
    z-index: 2;

    & + * {
        margin-top: -${Spacing.XS}px;
    }
`;

export const ContentWrapperStyled = styled(Stack).attrs({ flex: 1, direction: "column" })<{ blurred?: boolean }>`
    ${({ blurred }) => (blurred ? "filter: blur(3px);" : "")}
`;

export const DraggingAddonStyled = styled(Stack).attrs({
    justifyContent: "center",
    alignItems: "center",
})`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
`;

export const TooltipStyled = styled(TooltipRich).attrs({
    borderRadius: "XXS",
})`
    &.custom-tooltip {
        width: auto;
        padding: ${Spacing.XXS}px ${Spacing.XS}px;
    }
`;
