import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Databases, QueryResult } from "../../../models/DbModel";
import { IState } from "../../../Store";
import { useFilteredSites } from "../../../store/insights/FilterHooks";
import InsightsActions from "../../../store/insights/InsightsActions";
import { useDatabase } from "../../../store/insights/InsightsLoadHooks";
import { DamageReportType } from "../../../store/insights/InsightsModel";
import InsightsSelectors from "../../../store/insights/InsightsSelectors";
import { generateQueryKey } from "../../../store/insights/keys";
import { IDamageReportsState } from "../../../store/insights/types";
import { isFinished, RequestStatus } from "../../../store/RequestStatus";
import { getDamagedReportsCategoriesQuery } from "./ReportedDamages.queries";

export const useReportedDamages = (): {
    isLoading: boolean;
    damageReports: DamageReportType;
} => {
    const dispatch = useDispatch();
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const { siteIds } = useFilteredSites();
    const { queryStatus, damageReports, key } = useSelector<IState, IDamageReportsState>(InsightsSelectors.getDamageReports);

    const isLoading = useMemo(() => {
        return !dbReadyToQuery || !isFinished(queryStatus);
    }, [dbReadyToQuery, queryStatus]);

    const getDamageReports = useCallback(async () => {
        const reportByDamageQuery = getDamagedReportsCategoriesQuery("damaged_reports_categories", siteIds, selectedStartDate, selectedEndDate);
        const reportByDamageJob = execute(reportByDamageQuery.sql, reportByDamageQuery.params);
        const damages = await Promise.all([reportByDamageJob]);
        return formatDamageReportsToState(damages[0]?.results);
    }, [execute, siteIds, selectedStartDate, selectedEndDate]);

    useEffect(() => {
        const getDamages = async (queryKey: string) => {
            dispatch(InsightsActions.requestDamageReports(queryKey));
            const damageReports = await getDamageReports();
            dispatch(InsightsActions.damageReportsSuccess(damageReports, queryKey));
        };
        const newKey = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        if (!dbReadyToQuery || (key === newKey && (queryStatus === RequestStatus.loading || isFinished(queryStatus)))) {
            return;
        }
        getDamages(newKey);
    }, [dbReadyToQuery, queryStatus, dispatch, getDamageReports, siteIds, key, selectedStartDate, selectedEndDate]);

    return { isLoading, damageReports };
};

const formatDamageReportsToState = (damageReports: QueryResult[]): DamageReportType => {
    if (damageReports?.length) {
        const { values } = damageReports[0];

        return values.reduce((acc, value) => {
            const [siteId, category, damageCount] = value;

            return {
                ...acc,
                [siteId]: {
                    ...acc[siteId],
                    [category]: damageCount,
                },
            };
        }, {});
    }

    return {};
};
