import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sortByField } from "src/helpers/ArrayHelper";
import { addDays, addMilliseconds, differenceInDays } from "src/helpers/date";

import { RequestStatus } from "../../RequestStatus";
import { TABLE_TOTAL_KEY } from "../InsightsModel";
import InsightsSelectors from "../InsightsSelectors";
import { generateQueryKey } from "../keys";
import * as ToursActions from "./ToursActions";
import { getLocationToursQueryKey, getLocationToursQueryStatus } from "./ToursSelectors";
import { TourHistoricalCompareSummary, TourSummaryResponse } from "./ToursTypes";

const handleEmptyValue = (acc: number, curr: number): number => (acc || 0) + (curr || 0);

const sumTotal = (acc: TourSummaryResponse, curr: TourSummaryResponse): TourSummaryResponse => {
    return {
        ...acc,
        name: TABLE_TOTAL_KEY,
        count: handleEmptyValue(acc?.count, curr.count),
        exceptionsCount: handleEmptyValue(acc?.exceptionsCount, curr?.exceptionsCount),
        checkpointsScanned: handleEmptyValue(acc?.checkpointsScanned, curr?.checkpointsScanned),
        checkpointsMissed: handleEmptyValue(acc?.checkpointsMissed, curr?.checkpointsMissed),
    };
};

const mapTourResponse = (current: TourSummaryResponse[], prev: TourSummaryResponse[]): TourHistoricalCompareSummary[] => {
    const tours = current.concat(prev);
    tours.sort(sortByField("name"));
    const tourIds = tours.map((x) => x.mysTourId);
    const currentObj = current.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.mysTourId]: {
                ...curr,
            },
            total: sumTotal(acc[TABLE_TOTAL_KEY] || {}, curr),
        };
    }, {});
    const prevObj = prev.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.mysTourId]: {
                ...curr,
            },
            total: sumTotal(acc[TABLE_TOTAL_KEY] || {}, curr),
        };
    }, {});
    if (!tourIds.length) {
        return [];
    }
    return [...new Set(tourIds), TABLE_TOTAL_KEY].map((id) => ({
        mysTourId: id,
        name: currentObj[id]?.name || prevObj[id]?.name,
        count: currentObj[id]?.count || 0,
        exceptionsCount: currentObj[id]?.exceptionsCount || 0,
        checkpointsScanned: currentObj[id]?.checkpointsScanned || 0,
        checkpointsMissed: currentObj[id]?.checkpointsMissed || 0,
        histCount: prevObj[id]?.count || 0,
        histExceptionsCount: prevObj[id]?.exceptionsCount || 0,
        histCheckpointsScanned: prevObj[id]?.checkpointsScanned || 0,
        histCheckpointsMissed: prevObj[id]?.checkpointsMissed || 0,
    }));
};

export const useTours = () => {
    const { siteId = "" } = useParams<{ siteId?: string }>();
    const dispatch = useDispatch();
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const queryKey = useSelector(getLocationToursQueryKey);
    const queryStatus = useSelector(getLocationToursQueryStatus);
    const histDateRange = useMemo(() => {
        const dateDifference = differenceInDays(addMilliseconds(selectedEndDate, 1), selectedStartDate);
        return [addDays(selectedStartDate, -dateDifference), addMilliseconds(selectedStartDate, -1)];
    }, [selectedStartDate, selectedEndDate]);

    const requestTours = useCallback(async () => {
        if (!siteId) {
            return [];
        }
        const actualResult = await dispatch(
            ToursActions.requestLocationToursComparePeriod(siteId, {
                fromDateTime: selectedStartDate,
                toDateTime: selectedEndDate,
            }),
        );
        const historicalResult = await dispatch(
            ToursActions.requestLocationToursComparePeriod(siteId, {
                fromDateTime: histDateRange[0],
                toDateTime: histDateRange[1],
            }),
        );

        if (actualResult["error"] || historicalResult["error"]) {
            return [];
        }

        return mapTourResponse(actualResult["payload"], historicalResult["payload"]);
    }, [selectedStartDate, selectedEndDate, siteId, histDateRange, dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(ToursActions.requestLocationTours());
            const result = await requestTours();
            dispatch(ToursActions.locationToursSuccess(result, key));
        };
        const key = generateQueryKey({ locationIds: [siteId], fromDate: selectedStartDate, toDate: selectedEndDate });
        if (key !== queryKey && queryStatus !== RequestStatus.loading) {
            fetchData();
        }
    }, [selectedStartDate, selectedEndDate, siteId, queryKey, queryStatus]);
};
