import { formatDate, formatExplicit } from "src/helpers/date";

import { useSummaryPeriod, useSummaryViewMode } from "../shared/hooks";

export const useSummaryHeader = () => {
    const periods = useSummaryPeriod();
    const summaryViewMode = useSummaryViewMode();

    const periodLabel =
        summaryViewMode === "month" ? formatExplicit(periods.currentPeriod.start, "MMMM y") : formatDate(periods.currentPeriod.start, "longDate");

    return {
        periodLabel,
    };
};
