import { Breakpoints, type IconSizeType, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHistory } from "src/contexts/HistoryProvider";
import { useInsightsTabs } from "src/pages/Insights/InsightsTabs/InsightsTabs.hooks";

import { getInsightsActiveTabLabel } from "./BackButton.helpers";

type Props = {
    defaultPath?: string;
    defaultLabel?: string;
};

export const useBackButton = ({ defaultPath, defaultLabel }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { historyStack, previousPageLabel, navigateBack } = useHistory();
    const { activeTabLabelKey: insightsActiveTabLabelKey } = useInsightsTabs();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const previousPath = historyStack.at(-2);
    const isFirstHistoryEntry = historyStack.length < 2;
    const shouldRender = !isFirstHistoryEntry || defaultPath;
    const iconSize: IconSizeType = isMobile ? "L" : undefined;

    const getButtonText = (): string | undefined => {
        if (previousPath === "/insights") {
            return getInsightsActiveTabLabel(insightsActiveTabLabelKey);
        }

        if (isFirstHistoryEntry || previousPath === defaultPath) {
            return defaultLabel;
        }

        return previousPageLabel;
    };

    const handleClick = useCallback(() => {
        if (isFirstHistoryEntry && defaultPath) {
            navigate(defaultPath);
        } else {
            navigateBack();
        }
    }, [defaultPath, isFirstHistoryEntry, navigate, navigateBack]);

    return {
        shouldRender,
        buttonText: getButtonText() || t("common.back"),
        iconSize,
        handleClick,
    };
};
