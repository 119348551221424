import { type PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createContext } from "src/helpers/createContext";
import { useInsightsTabs } from "src/pages/Insights/InsightsTabs/InsightsTabs.hooks";

interface HistoryContextType {
    historyStack: string[];
    previousPageLabel: string | undefined;
    registerPageLabel: (label: string) => void;
    navigateBack: () => void;
}

const [HistoryContext, useHistory] = createContext<HistoryContextType>({
    defaultValue: {
        historyStack: [],
        previousPageLabel: "",
        registerPageLabel: () => null,
        navigateBack: () => null,
    },
    providerName: "HistoryProvider",
});

export const HistoryProvider = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [historyStack, setHistoryStack] = useState<string[]>([]);
    const [labelMap, setLabelMap] = useState<Record<string, string>>({});
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const { activeTab: insightsActiveTab, setActiveTab: setInsightsActiveTab } = useInsightsTabs();
    const previousPath = historyStack[currentIndex - 1];
    const previousPageLabel = labelMap[previousPath];

    useEffect(() => {
        setHistoryStack((prevStack) => {
            if (currentIndex < prevStack.length - 1) {
                return prevStack.slice(0, currentIndex + 1);
            }
            setCurrentIndex((prevIndex) => prevIndex + 1);
            return [...prevStack, location.pathname];
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const registerPageLabel = useCallback(
        (label: string) => {
            setLabelMap((prevMap) => {
                if (prevMap[location.pathname]) {
                    return prevMap;
                }
                return { ...prevMap, [location.pathname]: label };
            });
        },
        [location],
    );

    const navigateBack = useCallback(() => {
        if (currentIndex < 1) {
            return;
        }

        setCurrentIndex((prevIndex) => prevIndex - 1);
        navigate(previousPath);

        if (previousPath === "/insights") {
            setInsightsActiveTab(insightsActiveTab);
        }
    }, [currentIndex, previousPath, insightsActiveTab, navigate, setInsightsActiveTab]);

    const memoizedValue = useMemo(
        () => ({
            historyStack,
            previousPageLabel,
            registerPageLabel,
            navigateBack,
        }),
        [historyStack, previousPageLabel, navigateBack, registerPageLabel],
    );

    return <HistoryContext.Provider value={memoizedValue}>{children}</HistoryContext.Provider>;
};

export { useHistory };
