import { Background, Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { TIMELINE_CONTAINER_WIDTH } from "../../Timeline.constants";

const ELEMENT_WIDTH = "227px";

export const StackStyled = styled(Stack).attrs({
    direction: "column",
    padding: "S",
})`
    border-radius: 4px;
    gap: ${Spacing.XXS}px;
    background-color: ${Background.content};
    width: ${ELEMENT_WIDTH};

    @container TimelineContainer (max-width: ${TIMELINE_CONTAINER_WIDTH}) {
        width: auto;
    }

    @media (max-width: ${Breakpoints.XS}) {
        width: auto;
    }
`;
