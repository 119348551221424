import { Breakpoints } from "@secuis/ccp-react-components";

import { ColumnsPerBreakpoint, ScreenWidthVariant } from "./DraggableGrid.types";

export const GRID_ROW_SIZE = 210;

export const GRID_BREAKPOINTS: ColumnsPerBreakpoint = {
    lg: parseInt(Breakpoints.L),
    md: parseInt(Breakpoints.M),
    sm: parseInt(Breakpoints.S),
    xs: parseInt(Breakpoints.XS),
    xxs: 0,
};

export const LAYOUT_BREAKPOINTS: ScreenWidthVariant[] = ["lg", "md", "sm", "xs", "xxs"];

export const DEFAULT_COLUMNS_PER_BREAKPOINT: ColumnsPerBreakpoint = {
    lg: 3,
    md: 3,
    sm: 3,
    xs: 2,
    xxs: 1,
};
