import { useMemo } from "react";

import { featureFlags } from "../../../../data/featureFlags";
import { getFieldValueByFieldName } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { extractExceptionQuestionsFromReport } from "../../../../helpers/reportTemplates/extractExceptionQuestionsFromReport";
import { useFeatureFlag } from "../../../../hooks/featureFlags";
import { DynamicReportType } from "../../../../models/ReportModel";
import { TourMultiExceptionFields } from "../../../../models/reportTemplates";
import { ExceptionQuestionField } from "../ExceptionQuestionField";
import { TrueDeviationsDisclaimer } from "../TrueDeviationsDisclaimer";
import { isTrueException } from "./ExceptionQuestionsFields.helpers";

type Props = {
    report: DynamicReportType;
    sharedReportId?: string;
    shouldAutoTranslate?: boolean;
};

export const ExceptionQuestionsFields = ({ report, sharedReportId, shouldAutoTranslate = true }: Props) => {
    const serializedTrueExceptions = useMemo(() => getFieldValueByFieldName(TourMultiExceptionFields.trueExceptionQuestions, report), [report]);
    const trueExceptionQuestions: string[] = useMemo(() => (serializedTrueExceptions ? JSON.parse(serializedTrueExceptions) : []), [serializedTrueExceptions]);
    const questionsWithImages = useMemo(() => extractExceptionQuestionsFromReport(report), [report]);
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);

    return (
        <>
            {isTrueDeviationsEnabled && isTrueException && <TrueDeviationsDisclaimer />}
            {questionsWithImages.map(({ question, answer, image }) => (
                <ExceptionQuestionField
                    key={question}
                    question={question}
                    answer={answer}
                    isTrueException={isTrueException(question, trueExceptionQuestions)}
                    shouldAutoTranslate={shouldAutoTranslate}
                    sharedReportId={sharedReportId}
                    image={image}
                />
            ))}
        </>
    );
};
