import { Breakpoints } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Widget } from "../shared/components";

export const WidgetStyled = styled(Widget)<{ withMinHeight?: boolean }>`
    ${({ withMinHeight }) =>
        withMinHeight
            ? `
    & {
        min-height: 193px;
        @media (max-width: ${Breakpoints.XS}) {
            min-height: 220px;
        }
    }`
            : ""}
`;
