import { Stack } from "@secuis/ccp-react-components";
import { PageTabs } from "src/components/shared/PageTabs";
import { PageWrapper } from "src/components/shared/PageWrapper";

import { SETTINGS_TABS } from "./Settings.constants";
import { SettingsHeader } from "./SettingsHeader/SettingsHeader";

export const Settings = () => {
    return (
        <PageWrapper>
            <Stack direction="column" flex={1}>
                <SettingsHeader />
                <PageTabs tabs={SETTINGS_TABS} />
            </Stack>
        </PageWrapper>
    );
};
