import { Spacing } from "@secuis/ccp-react-components";

import { DraggableWidgetWrapper } from "../shared/components/DraggableWidgetWrapper";
import { useDraggableGrid } from "./DraggableGrid.hooks";
import { ResponsiveGridStyled } from "./DraggableGrid.styles";
import { DraggableGridProps } from "./DraggableGrid.types";

export const DraggableGrid = (props: DraggableGridProps) => {
    const { editable, layouts, currentLayout, draggedId, breakpoints, columns, widgetsMap, rowHeight, handleLayoutChange, handleDragStop, handleDragStart } =
        useDraggableGrid(props);

    return (
        <ResponsiveGridStyled
            layouts={layouts}
            onLayoutChange={handleLayoutChange}
            onDragStart={handleDragStart}
            onDragStop={handleDragStop}
            margin={[Spacing.S, Spacing.S]}
            containerPadding={[0, 0]}
            compactType="vertical"
            isDraggable={editable}
            preventCollision={false}
            allowOverlap={false}
            isResizable={false}
            rowHeight={rowHeight}
            breakpoints={breakpoints}
            resizeHandles={[]}
            cols={columns}
        >
            {currentLayout?.map(({ i }) => {
                const Content = widgetsMap[i]?.Content;

                return (
                    Content && (
                        <DraggableWidgetWrapper key={i} dragged={draggedId === i} editMode={editable}>
                            <Content />
                        </DraggableWidgetWrapper>
                    )
                );
            })}
        </ResponsiveGridStyled>
    );
};
