import { getFieldValueByFieldName } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { ActivityLogFields } from "../../../models/reportTemplates/ActivityLog";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { TextAreaSummaryField } from "../SharedComponents/TextArea";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const shouldRenderActivitiesSummary = (report, sharedReportId) => {
    return !!(
        getUnknownFieldsComponents(report, sharedReportId, Object.values(ActivityLogFields), true).length ||
        getFieldValueByFieldName(ActivityLogFields.wasTheActivityLoggedIntoTheJournal, report) ||
        getFieldValueByFieldName(ActivityLogFields.additionalDescription, report) ||
        getFieldValueByFieldName(ActivityLogFields.debriefOfColleagueHappened, report) ||
        getFieldValueByFieldName(ActivityLogFields.passDownReportCompleted, report) ||
        getFieldValueByFieldName(ActivityLogFields.anyOpenItemsToBeAddressed, report) ||
        getFieldValueByFieldName(ActivityLogFields.commanderPresenceOnSite, report) ||
        getFieldValueByFieldName(ActivityLogFields.nameOfCustomer, report) ||
        getFieldValueByFieldName(ActivityLogFields.activityLogJob, report) ||
        getFieldValueByFieldName(ActivityLogFields.activityLogJobId, report)
    );
};

export const getActivityLogReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.activities",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    description: getFieldValueByFieldName(ActivityLogFields.comments, report),
                    sharedReportId: sharedReportId,
                    imagesData: report.images,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    conditionalRender(shouldRenderActivitiesSummary(report, sharedReportId), () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.detailedInfo.activitiesSummary",
                collapsedLabel: "reportdetails.summary.moreDetails",
                expandedLabel: "reportdetails.summary.lessDetails",
            },
            children: [
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: "reportdetails.activityLog.wasTheActivityLoggedIntoTheJournal",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.wasTheActivityLoggedIntoTheJournal, report),
                        mobileDirection: "column",
                        isRequired: false,
                    },
                },
                {
                    component: TextAreaSummaryField,
                    props: {
                        title: "reportdetails.activityLog.additionalDescription",
                        content: getFieldValueByFieldName(ActivityLogFields.additionalDescription, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: "reportdetails.activityLog.debriefOfColleagueHappened",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.debriefOfColleagueHappened, report),
                        mobileDirection: "column",
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: "reportdetails.activityLog.passDownReportCompleted",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.passDownReportCompleted, report),
                        mobileDirection: "column",
                        isRequired: false,
                    },
                },
                {
                    component: TextAreaSummaryField,
                    props: {
                        title: "reportdetails.activityLog.anyOpenItemsToBeAddressed",
                        content: getFieldValueByFieldName(ActivityLogFields.anyOpenItemsToBeAddressed, report),
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: "reportdetails.activityLog.commanderPresenceOnSite",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.commanderPresenceOnSite, report),
                        mobileDirection: "column",
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: "reportdetails.activityLog.nameOfCustomer",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.nameOfCustomer, report),
                        mobileDirection: "column",
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: "reportdetails.activityLog.job",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.activityLogJob, report),
                        mobileDirection: "column",
                        isRequired: false,
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: "reportdetails.activityLog.jobId",
                        fieldValue: getFieldValueByFieldName(ActivityLogFields.activityLogJobId, report),
                        mobileDirection: "column",
                        isRequired: false,
                    },
                },
                ...getUnknownFieldsComponents(report, sharedReportId, Object.values(ActivityLogFields)),
            ],
        };
    }),
];
