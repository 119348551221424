import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { DefaultItemFieldWrapper } from "../DefaultItemField";
import { LEGEND_MOBILE_PADDING, TIMELINE_CONTAINER_WIDTH } from "./Timeline.constants";

export const TimelineWrapperStyled = styled(DefaultItemFieldWrapper)`
    container-type: inline-size;
    container-name: TimelineContainer;
    margin-bottom: 0;
`;

export const StackStyled = styled(Stack).attrs({
    justifyContent: "space-between",
})`
    padding: ${Spacing.XS}px 0;
    row-gap: ${Spacing.XS}px;

    @container TimelineContainer (max-width: ${TIMELINE_CONTAINER_WIDTH}) {
        flex-direction: column;
        row-gap: ${Spacing.M}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        flex-direction: column;
        row-gap: ${Spacing.M}px;
    }
`;

export const LegendWrapper = styled(Stack).attrs({
    direction: "column",
    gap: "XS",
})`
    @container TimelineContainer (max-width: ${TIMELINE_CONTAINER_WIDTH}) {
        padding: ${Spacing.S}px ${LEGEND_MOBILE_PADDING};
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px ${LEGEND_MOBILE_PADDING};
    }
`;
