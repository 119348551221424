import { Dictionary } from "@reduxjs/toolkit";
import { WorkerExecResult } from "src/models/DbModel";
import { CategoryLevel1, CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import { getParameterizedArray, getReportQueryTimeRangeCondition, getSqlStringifiedArrayFromObject } from "src/sql/scripts/queryUtilities";

export const getSiteEventsCountQuery = ({ siteIds, startDate, endDate }: { siteIds: string[]; startDate: Date; endDate: Date; categories: string[] }) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT location_id, category_level1, COUNT(*) as eventsCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND ${getReportQueryTimeRangeCondition(startDate, endDate)}
                AND location_id IN (${stringifiedSites})
            GROUP BY location_id, category_level1
            ORDER BY eventsCount DESC
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseSiteEventsResult = ({ results }: WorkerExecResult): Dictionary<Partial<Record<CategoryLevel1, number>>> => {
    const values = results[0]?.values;

    if (!values?.length) {
        return {};
    }

    return values.reduce((acc, [locationId, category, count]) => {
        const siteInfo = acc[locationId] ?? {};

        siteInfo[category] = (siteInfo[category] ?? 0) + count;
        acc[locationId] = siteInfo;

        return acc;
    }, {});
};
