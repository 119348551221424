import { Box, Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SeverityBadge } from "src/components/Reports/SharedComponents/Badges";
import { SwipeActions } from "src/components/shared/SwipeActions/SwipeActions";
import type { SwipeActionItem } from "src/components/shared/SwipeActions/types";
import { featureFlags } from "src/data/featureFlags";
import { getCombinedExceptionAndTourName, getReportTemplateShortTranslation } from "src/helpers/ReportHelper";
import { resolveReportSubheader } from "src/helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";
import { ReportsListItemType, ReportTemplateEnum, SiteZoneEnum } from "src/models/ReportModel";
import { TourFields } from "src/models/reportTemplates/Tour";
import { useTrackReportVisit } from "src/store/insights/CardsHooks";
import LocationsSelector from "src/store/locations/LocationsSelectors";

import { useTrueException } from "./ReportsListItem.hooks";
import {
    ColumnActionsStackStyled,
    ColumnStackStyled,
    ItemDetailsStyled,
    ListItemStyled,
    ListItemWrapperStyled,
    ReportIdTextStyled,
    ReportNamesStyled,
    TemplateStackStyled,
    UnreadDotWrapperStyled,
    WrapabbleFlexStyled,
} from "./ReportsListItem.styles";
import { ListItemCategory } from "./shared/ListItemCategory";
import { ListItemContextMenu } from "./shared/ListItemContextMenu";
import { ListItemCounters } from "./shared/ListItemCounters";
import { ListItemDate } from "./shared/ListItemDate";
import { ListItemPatrolTourSummary } from "./shared/ListItemPatrolTourSummary";
import { UnreadDot } from "./shared/SharedStyles";
import { TrueExceptionCount } from "./shared/TrueExceptionCount";
import { ListItemVehicleLine } from "./VehicleInspection/ListItemVehicleLine";

type Props = {
    report: ReportsListItemType;
    isActive: boolean;
    unread: boolean;
    onClick: (id: string, locationId: string, type: string) => void;
    onSwipeStart?: () => void;
    onSwipeEnd?: () => void;
};

export const ReportsListItem = ({ report, isActive, unread, onClick, onSwipeStart, onSwipeEnd }: Props) => {
    const [localUnread, setLocalUnread] = useState(unread);
    const [isFocusable, setIsFocusable] = useState(true);
    const siteObject = useSelector(LocationsSelector.getAuthorizedLocationById(report.locationId));
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { setReportReadFlag, setTourReadFlag } = useTrackReportVisit();
    const isPatrolTour = report.type === ReportTemplateEnum.patrolTour;
    const { trueExceptionCount } = useTrueException(report);
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);

    const rootTemplate = useMemo(() => {
        if (isPatrolTour) {
            return report.properties.find((p) => p.key === TourFields.name)?.value;
        }
        if (report.type === ReportTemplateEnum.tourException || report.type === ReportTemplateEnum.tourMultiException) {
            const tourName = report.properties.find((p) => p.key === "tourName")?.value;
            return getCombinedExceptionAndTourName(report.type, tourName);
        }
        return getReportTemplateShortTranslation(report.type);
    }, [isPatrolTour, report]);

    useEffect(() => {
        setLocalUnread(unread);
    }, [unread]);

    const handleItemClick = useCallback(() => {
        setIsFocusable(true);
        setLocalUnread(false);
        onClick(report.id, report.locationId, report.type);
    }, [onClick, report.id, report.locationId, report.type]);

    const handleItemKeyDown = useOnEnterSpaceKeyDown(handleItemClick, true);

    const handleMarkRead = useCallback(() => {
        if (isPatrolTour) {
            setTourReadFlag(report, true);
        } else {
            setReportReadFlag(report, true);
        }
        setLocalUnread(false);
    }, [isPatrolTour, setTourReadFlag, report, setReportReadFlag]);

    const handleMarkUnread = useCallback(() => {
        if (isPatrolTour) {
            setTourReadFlag(report, false);
        } else {
            setReportReadFlag(report, false);
        }
        setLocalUnread(true);
    }, [isPatrolTour, setTourReadFlag, report, setReportReadFlag]);

    const swipeActions: SwipeActionItem[] = useMemo(() => {
        const markReadUnreadItem: SwipeActionItem = localUnread
            ? {
                  label: t("reports.status.read"),
                  icon: "EnvelopeOpened",
                  disabled: !localUnread,
                  onClick: handleMarkRead,
              }
            : {
                  label: t("reports.status.unread"),
                  icon: "Envelope",
                  disabled: localUnread,
                  onClick: handleMarkUnread,
              };

        return [markReadUnreadItem];
    }, [handleMarkRead, handleMarkUnread, localUnread, t]);

    const isPatrol = useMemo(() => {
        return report.type === ReportTemplateEnum.patrolTour;
    }, [report]);

    const isVehicleInspectionReport = report.type === ReportTemplateEnum.vehicleInspection;

    const siteLocationText = useMemo(() => {
        if (!report.siteLocation) {
            return null;
        }
        return SiteZoneEnum[report.siteLocation] ? t(`siteLocations.${SiteZoneEnum[report.siteLocation]}`) : report.siteLocation;
    }, [report, t]);

    const reportSubType = resolveReportSubheader(report.type, report.subType);

    return (
        <ListItemWrapperStyled>
            <SwipeActions actions={swipeActions} breakpoint={Breakpoints.XS} onSwipeStart={onSwipeStart} onSwipeEnd={onSwipeEnd} closeOnClick closeOnTouchStart>
                <ListItemStyled
                    data-testid="desktop-report-list-item"
                    key={report.id}
                    isActive={isActive}
                    isFocusable={isFocusable}
                    tabIndex={0}
                    role="button"
                    onClick={handleItemClick}
                    onKeyDown={handleItemKeyDown}
                >
                    <ColumnStackStyled>
                        <ReportNamesStyled data-testid="report-name">
                            <UnreadDotWrapperStyled>{localUnread && <UnreadDot data-testid="report-unread" />}</UnreadDotWrapperStyled>
                            <TemplateStackStyled alignItems="baseline" flexWrap="wrap">
                                <Text bold color="primary">
                                    {rootTemplate}
                                </Text>
                                {reportSubType && !isPatrolTour && (
                                    <Text micro bold color="neutral">
                                        {reportSubType}
                                    </Text>
                                )}
                            </TemplateStackStyled>
                        </ReportNamesStyled>
                        {isPatrol && <ListItemPatrolTourSummary report={report} />}
                        {report?.categoryLevel1 ? (
                            <ItemDetailsStyled>
                                <Stack gap="XS" alignItems="center" direction="row" flexWrap="wrap">
                                    <SeverityBadge data-testid="report-severity" severityLevel={report.severityLevel} />
                                    {isTrueDeviationsEnabled && trueExceptionCount > 0 && <TrueExceptionCount exceptionCount={trueExceptionCount} />}
                                    {(!isTrueDeviationsEnabled || trueExceptionCount === 0) && <ListItemCategory report={report} />}
                                </Stack>
                                {trueExceptionCount > 0 && isTrueDeviationsEnabled && (
                                    <Box mt="XS">
                                        <ListItemCategory report={report} />
                                    </Box>
                                )}
                            </ItemDetailsStyled>
                        ) : (
                            <ItemDetailsStyled>
                                <Stack gap="XS" alignItems="center" direction="row" flexWrap="wrap">
                                    {isTrueDeviationsEnabled && trueExceptionCount > 0 && <TrueExceptionCount exceptionCount={trueExceptionCount} />}
                                </Stack>
                            </ItemDetailsStyled>
                        )}
                        {isVehicleInspectionReport && <ListItemVehicleLine report={report} />}
                        <WrapabbleFlexStyled>
                            <Text data-testid="list-item-site-name" micro bold>
                                {siteObject?.name}
                            </Text>
                            {siteLocationText && (
                                <Text data-testid="site-location-list-view" micro color="neutral">
                                    {" "}
                                    {`• ${siteLocationText}`}
                                </Text>
                            )}
                        </WrapabbleFlexStyled>
                    </ColumnStackStyled>
                    <ColumnStackStyled justifyContent="space-between">
                        <Stack alignItems="flex-end" direction="column" gap={isMobile ? "0" : "XS"}>
                            <ListItemDate reportDateTime={report.reportDateTime} siteTimezone={report.siteIanaTimezone} />
                            {report.sourceId && !isPatrol && (
                                <ReportIdTextStyled micro color="neutral">{`${t("reportlist.subtitle.report")} # ${report.sourceId}`}</ReportIdTextStyled>
                            )}
                        </Stack>
                        <Stack alignItems="flex-end" justifyContent="flex-end" data-testid="list-item-image-comment-icons">
                            <ListItemCounters report={report} />
                        </Stack>
                    </ColumnStackStyled>
                    {!isMobile && (
                        <ColumnActionsStackStyled>
                            <Box
                                onKeyDown={(e: React.KeyboardEvent) => e.stopPropagation()}
                                onMouseDown={() => {
                                    setIsFocusable(false);
                                }}
                                onMouseUp={() => {
                                    setIsFocusable(true);
                                }}
                            >
                                <ListItemContextMenu isRead={!localUnread} onMarkRead={handleMarkRead} onMarkUnread={handleMarkUnread} />
                            </Box>
                        </ColumnActionsStackStyled>
                    )}
                </ListItemStyled>
            </SwipeActions>
        </ListItemWrapperStyled>
    );
};
