import { ButtonText } from "@secuis/ccp-react-components";

import { useBackButton } from "./BackButton.hooks";

type Props = {
    defaultPath?: string;
    defaultLabel?: string;
};

export const BackButton = ({ defaultPath, defaultLabel }: Props) => {
    const { shouldRender, buttonText, iconSize, handleClick } = useBackButton({ defaultPath, defaultLabel });

    if (!shouldRender) {
        return null;
    }

    return (
        <ButtonText data-testid="back-button" color="primary" direction="row" icon="BackNavigation" iconSize={iconSize} onClick={handleClick}>
            {buttonText}
        </ButtonText>
    );
};
