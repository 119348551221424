import i18next from "i18next";
import { isEmpty, max, uniq } from "lodash";
import { useMemo } from "react";
import { formatExplicit, getDay, setDay } from "src/helpers/date";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";

import { useApiRequest } from "../../../../hooks/accessApi";
import { parseDateRangeRequestBody } from "../../../../store/insights/helpers";
import { STYLES } from "../shared/components/Graph/Graph.constants";
import { GraphData } from "../shared/components/Graph/Graph.types";
import { useSummaryPeriod } from "../shared/hooks";
import { MAX_PEAK_DAYS_COUNT, WEEKDAYS_ORDER } from "./DeviantDayWidget.constant";
import { parseResult, query } from "./DeviantDayWidget.queries";
import { DailyExceptionsSummary } from "./DeviantDayWidget.type";

export const useDeviantDayWidget = () => {
    const { siteIds } = useFilteredSites();
    const { currentPeriod } = useSummaryPeriod();
    const { reportsPerDay, isLoading } = useDeviantDayData(siteIds, currentPeriod.start, currentPeriod.end);

    const maxDataValue = useMemo(() => {
        if (!reportsPerDay || isEmpty(reportsPerDay)) {
            return 0;
        }
        return max(Object.values(reportsPerDay));
    }, [reportsPerDay]);

    const chartData = useMemo(() => parseToChartData(reportsPerDay, maxDataValue), [reportsPerDay, maxDataValue]);

    const shouldHighlightMaxDataValue = useMemo(
        () => chartData.filter((item) => item.value === maxDataValue).length <= MAX_PEAK_DAYS_COUNT,
        [chartData, maxDataValue],
    );

    return {
        chartData,
        isLoading: isLoading,
        maxDataValue,
        shouldHighlightMaxDataValue,
    };
};

const getWeekDayLabel = (weekDay: string, short: boolean = true): string => {
    const weekDayNumber = parseInt(weekDay);
    const date = setDay(new Date(), weekDayNumber, { weekStartsOn: 1 });
    return formatExplicit(date, short ? "EEEEEE" : "EEEE");
};

const parseToChartData = (data: Record<string, number>, maxValue: number): GraphData => {
    if (!data) {
        return [];
    }

    const shouldHighlight = WEEKDAYS_ORDER.filter((item) => data[item] === maxValue).length <= MAX_PEAK_DAYS_COUNT;

    return WEEKDAYS_ORDER.map((dayKey) => {
        const totalEvents = data[dayKey] || 0;
        const label = getWeekDayLabel(dayKey, false);
        const name = getWeekDayLabel(dayKey);

        return {
            key: name,
            label: label,
            name: name,
            value: totalEvents,
            color: shouldHighlight && maxValue === totalEvents ? STYLES.barColor.highlight.default : STYLES.barColor.default.default,
            tooltip: [`${i18next.t("insights.summary.deviantDay")}: ${label}`, `${totalEvents} ${i18next.t("common.event", { count: totalEvents })}`],
        };
    });
};

const useDeviantDayData = (siteIds: string[], startDate: Date, endDate: Date) => {
    const reportsPerDay: Record<string, number> = {};
    const { queryResult, isLoading: isLoadingReports } = useSqlQuery(query, parseResult, {
        siteIds,
        startDate,
        endDate,
    });
    const { exceptionsPerDay, isLoading: isLoadingExceptions } = useExceptionsPerDay(siteIds, startDate, endDate);
    uniq([...Object.keys(queryResult ?? {}), ...Object.keys(exceptionsPerDay)]).forEach((weekdayNumber) => {
        reportsPerDay[weekdayNumber] = (queryResult?.[weekdayNumber]?.totalEvents ?? 0) + (exceptionsPerDay[weekdayNumber] ?? 0);
    });

    return {
        reportsPerDay,
        isLoading: isLoadingExceptions || isLoadingReports,
    };
};

const useExceptionsPerDay = (siteIds: string[], startDate: Date, endDate) => {
    const { data, isLoading } = useApiRequest<DailyExceptionsSummary>(
        `/v2/global/insights/tour-exc/summary/daily`,
        "POST",
        parseDateRangeRequestBody({ fromDateTime: startDate, toDateTime: endDate, siteIds }),
    );
    const exceptionsPerDay = (data ?? []).reduce(
        (acc, item) => {
            const weekdayNumber = getDay(new Date(item.dateTime)).toString();
            acc[weekdayNumber] = (acc[weekdayNumber] ?? 0) + item.count;

            return acc;
        },
        {} as Record<string, number>,
    );

    return {
        isLoading,
        exceptionsPerDay,
    };
};
