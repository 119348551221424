import { Icon, IconType, Stack, Text } from "@secuis/ccp-react-components";
import { useMemo } from "react";

import { checkIfUnknown } from "../../../../../helpers/ReportHelper";
import { ReportsListItemType, ReportTemplateEnum } from "../../../../../models/ReportModel";

type PropsType = {
    report: ReportsListItemType;
};

export const ListItemCounters = ({ report }: PropsType) => {
    const { id, imageCount, commentsCount, type } = report;

    const counters = useMemo(() => {
        const isUnknownType = checkIfUnknown(type);
        const counters: { count: number; translationKey: string; icon: IconType }[] = [
            {
                count: Number(commentsCount),
                translationKey: "report.commentsCount",
                icon: "Message",
            },
        ];

        if (!([ReportTemplateEnum.tourException, ReportTemplateEnum.patrolTour] as string[]).includes(type)) {
            counters.unshift({
                count: Number(!isUnknownType ? imageCount : 0),
                translationKey: "report.imagesCount",
                icon: "Image",
            });
        }

        return counters.filter(({ count }) => count);
    }, [imageCount, commentsCount, type]);

    if (!counters.length) {
        return null;
    }

    return (
        <Stack gap="XS" alignItems="center">
            {counters.map(({ count, translationKey, icon }) => (
                <Stack alignItems="center" gap="XXS" key={`${id}-${translationKey}`}>
                    <Icon variant={icon} size="S" color="tertiary" />
                    <Text color="neutral" small>
                        {count}
                    </Text>
                </Stack>
            ))}
        </Stack>
    );
};
