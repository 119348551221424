import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TrendingEvents } from "src/pages/Insights/Events/TrendingEvents";
import { TrendIndicatorBar } from "src/pages/Insights/shared/components/TrendIndicator";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { Barchart } from "../../Barchart/Barchart";
import { BarchartBreadcrumbs } from "../../Barchart/BarchartBreadcrumbs";
import { useBarchartData } from "../../Barchart/hooks/useBarchartData";
import { StackStyled } from "../../InsightsSitePage.styled";
import { SelectedCategories } from "../../InsightsSitePage.types";

interface IncidentsTabProps {
    selectedCategories: SelectedCategories;
    levelChangeHandler: (selectedCategory: SelectedCategories) => void;
}

export const IncidentsTab = ({ selectedCategories, levelChangeHandler }: IncidentsTabProps) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { siteId } = useParams<{ siteId?: string }>();
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate);

    const { data: incidents, isLoading: isBarchartDataLoading } = useBarchartData(
        selectedCategories.level2 ?? selectedCategories.level1,
        siteId,
        startDate,
        endDate,
    );

    const isSingleCategory = incidents.length === 1;
    const categoryKey = isSingleCategory && !selectedCategories.level1 ? incidents[0].categoryKey : selectedCategories.level2 ?? selectedCategories.level1;
    const trendIndicatorTitle = isSingleCategory ? t(`incident.category.${incidents[0].categoryKey}`) : "";
    const trendIndicatorTargetCategory = isSingleCategory ? incidents[0].categoryKey : "";

    useEffect(() => {
        if (!isBarchartDataLoading && incidents.length === 0) {
            levelChangeHandler({ level1: null, level2: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidents.length, isBarchartDataLoading]);

    useEffect(() => {
        if (selectedCategories.level1) levelChangeHandler({ level1: null, level2: null });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return (
        <StackStyled ph={isMobile ? "S" : "L"} mt={isMobile ? "S" : "L"} direction="column" flex={1} gap="S">
            {!isMobile && selectedCategories.level1 && (
                <BarchartBreadcrumbs categoryLevel1={selectedCategories.level1} categoryLevel2={selectedCategories.level2} setBarchart={levelChangeHandler} />
            )}
            <TrendIndicatorBar
                categoryKey={categoryKey}
                siteId={siteId}
                title={trendIndicatorTitle}
                selectedCategories={selectedCategories}
                targetCategory={trendIndicatorTargetCategory}
                levelChangeHandler={levelChangeHandler}
            />
            {incidents.length !== 1 && (
                <Barchart
                    categoryLevel1={selectedCategories.level1}
                    categoryLevel2={selectedCategories.level2}
                    data={incidents}
                    levelChangeHandler={levelChangeHandler}
                    isLoading={isBarchartDataLoading}
                />
            )}
            {!selectedCategories.level1 && <TrendingEvents />}
        </StackStyled>
    );
};
