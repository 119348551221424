import { Stack } from "@secuis/ccp-react-components";

import { IconStyled, TextStyled } from "./Tag.styled";
import type { TagProps } from "./Tag.types";

export const Tag = ({ text, icon }: TagProps) => {
    return (
        <Stack alignItems="center" mb="XS">
            {icon && <IconStyled variant={icon} size="S" color="accent" />}
            <TextStyled micro>{text}</TextStyled>
        </Stack>
    );
};
