import { DateType, formatDateTime, formatDateTimeWithTimezone, isAllDatesOfSameDay } from "src/helpers/date";
import { DateTimeVariant } from "src/helpers/date/types";
import { CalloutLog, CalloutStatus } from "src/models/ReportModel";

import { DEFAULT_ICON, timelineEventsConfig } from "./Timeline.constants";
import { formatTimelineDuration, getColorByStatusTag, normalizeLogTags } from "./Timeline.helpers";

export const useTimeline = (fieldValue: { logs: CalloutLog[] }, shouldAutoTranslate: boolean, timeZone: string, isPdf: boolean = false) => {
    const { logs: rawLogs = [] } = fieldValue;
    const logs = normalizeLogTags(rawLogs);
    const isTheSameDay = isAllDatesOfSameDay(logs.filter((l) => l.createdOn).map((l) => new Date(l.createdOn)));
    const timeVariant = isTheSameDay ? "shortTime" : "midDateTime";
    const formatEventDateTime = (date: DateType, variant: DateTimeVariant) =>
        timeZone ? formatDateTimeWithTimezone(date, timeZone, timeVariant) : formatDateTime(date, variant);
    const responseTime = formatTimelineDuration(CalloutStatus.received, CalloutStatus.arrivedOnSite, logs);
    const timeOneSite = formatTimelineDuration(CalloutStatus.arrivedOnSite, CalloutStatus.departed, logs);

    const timelineItems = logs.map((x, index) => {
        const isLast = logs.length - 1 === index;
        const config = timelineEventsConfig[x.status.tag];
        return {
            key: x.id,
            iconType: config?.icon || DEFAULT_ICON,
            status: config?.text || x.status.name,
            dateTime: x.createdOn && formatEventDateTime(new Date(x.createdOn), timeVariant),
            lineColor: isLast ? null : getColorByStatusTag(x.status.tag, logs, isPdf),
            enableAutoTranslate: shouldAutoTranslate && !config?.text,
        };
    });

    return {
        timelineItems,
        responseTime,
        timeOneSite,
    };
};
