import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ScheduleFilters } from "../../components/Schedule/ScheduleFilters";
import { ScheduleTable } from "../../components/Schedule/ScheduleTable";
import LocationsSelectors from "../../store/locations/LocationsSelectors";
import { InsightsWrapper } from "../Insights/Common.styled";
import { useTasks } from "./SchedulePage.hooks";

type Props = {
    locationId: string;
};

export const ScheduleTableSection = ({ locationId }: Props) => {
    const { t } = useTranslation();
    const locationDetails = useSelector(LocationsSelectors.getAuthorizedLocationById(locationId));
    const { getTasks, isLoading, isSuccess, data } = useTasks();

    return (
        <InsightsWrapper paddingTop={"M"} paddingHorizontal={["L", "L", "S"]} direction="column">
            <ScheduleFilters tooltip={t("schedule.titleTooltip")} getTasks={getTasks} locationId={locationId} />
            <ScheduleTable siteName={locationDetails.name} isLoading={isLoading} isSuccess={isSuccess} tasks={data} />
        </InsightsWrapper>
    );
};
