import { Breakpoints, Datepicker, DateRangeChipModel, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";

import { DatepickerInputStyled, DatepickerInputWrapperStyled, DatepickerWrapperStyled } from "./DateInput.styles";

type Props = {
    label?: string;
    placeholder?: string;
    selectedStartDate: Date;
    selectedEndDate: Date;
    onDateChange: (startDate: Date, endDate: Date) => void;
    onCalendarOpen?: () => void;
    mode: "singleMonth" | "doubleMonth";
    dateRangeChips?: DateRangeChipModel[];
    isDaySelection?: boolean;
};

export const DateInput = ({
    label,
    placeholder,
    selectedStartDate,
    selectedEndDate,
    mode,
    onDateChange,
    onCalendarOpen,
    dateRangeChips,
    isDaySelection = false,
}: Props) => {
    const { t, i18n } = useTranslation();
    const isTablet = useHasMaxWidth(Breakpoints.S);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const dateInputRef = useRef(null);
    const datepickerWrapperRef = useRef(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [startDate, setStartDate] = useState(selectedStartDate);
    const [endDate, setEndDate] = useState(selectedEndDate);
    const [offsetTop, setOffsetTop] = useState(0);
    const [offsetRight, setOffsetRight] = useState(0);
    const blockFutureFromDays = 1;

    const showCalendar = useCallback(() => {
        setIsCalendarVisible(true);
        onCalendarOpen?.();
    }, [onCalendarOpen]);

    const setCalendarPosition = useCallback(() => {
        if (isMobile || !dateInputRef?.current || !datepickerWrapperRef?.current) {
            return;
        }

        let bounds = dateInputRef.current.getBoundingClientRect();

        if (bounds.bottom + 500 > window.innerHeight) {
            dateInputRef.current.scrollIntoView(true);
        }

        bounds = dateInputRef.current.getBoundingClientRect();

        const datepickerWrapperWidth = datepickerWrapperRef.current.getBoundingClientRect().width;
        const offsetTop = bounds.top + bounds.height + Spacing.XS || 0;
        const offsetRight = isTablet ? (window.innerWidth - datepickerWrapperWidth) / 2 : window.innerWidth - bounds.right || 0;

        setOffsetTop(offsetTop);
        setOffsetRight(offsetRight);
    }, [isMobile, isTablet, dateInputRef, setOffsetTop, setOffsetRight]);

    useLayoutEffect(() => {
        if (isCalendarVisible) {
            setCalendarPosition();
        }
    }, [isCalendarVisible, setCalendarPosition]);

    const hideCalendar = useCallback(() => {
        onDateChange(startDate, endDate);
        setIsCalendarVisible(false);
    }, [endDate, startDate, onDateChange]);

    useOnEscapeEventListener(hideCalendar, null, isCalendarVisible);

    useEffect(() => {
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
    }, [selectedEndDate, selectedStartDate]);

    const renderDatePicker = () => {
        const datePicker = (
            <DatepickerWrapperStyled ref={datepickerWrapperRef} data-testid="date-picker" offsetRight={offsetRight} offsetTop={offsetTop}>
                <Datepicker
                    mode={mode}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={(date) => {
                        if (!date) {
                            return;
                        }
                        setStartDate(date);
                    }}
                    setEndDate={(date) => {
                        if (!date) {
                            return;
                        }
                        setEndDate(date);
                    }}
                    languageCode={i18n.language}
                    blockFutureFromDays={blockFutureFromDays}
                    saveButtonLabel={t("common.save")}
                    hideCalendar={hideCalendar}
                    dateRangeChips={dateRangeChips}
                    isDaySelection={isDaySelection}
                />
            </DatepickerWrapperStyled>
        );
        if (isMobile) {
            // safari on mobile overlaps the fixed positioned datepicker with the navbar
            return ReactDOM.createPortal(datePicker, document.body);
        }
        return datePicker;
    };

    return (
        <DatepickerInputWrapperStyled ref={dateInputRef}>
            <DatepickerInputStyled
                startDate={startDate}
                endDate={endDate}
                clearDates={() => void 0}
                disabledClear={true}
                showCalendar={showCalendar}
                placeholder={placeholder ?? t("datepicker.placeholder")}
                label={label}
                isOpen={isCalendarVisible}
                languageCode={i18n.language}
                format="PP"
            >
                {isCalendarVisible && renderDatePicker()}
            </DatepickerInputStyled>
        </DatepickerInputWrapperStyled>
    );
};
