import { Breakpoints, Widget as CcpWidget, type WidgetProps } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { QaProps } from "src/models";
import styled from "styled-components";

import { featureFlags } from "../../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../../hooks/featureFlags";

type Props = WidgetProps & QaProps;

const CcpWidgetStyled = styled(CcpWidget)<{ withMinHeight?: boolean }>`
    ${({ withMinHeight }) => (withMinHeight ? `min-height: 460px;` : "")};

    [role="tooltip"] {
        max-width: 365px;

        @media (max-width: ${Breakpoints.XS}) {
            max-width: 85vw;
        }
    }
`;

export const Widget = ({ emptyStateProps, ...props }: Props) => {
    const { t } = useTranslation();
    const isCustomizable = useFeatureFlag(featureFlags.personalizeKpiSummary);

    return (
        <CcpWidgetStyled
            withMinHeight={!isCustomizable}
            emptyStateProps={{
                title: emptyStateProps?.title ?? t("common.noDataToDisplay"),
                subtitle: emptyStateProps?.subtitle ?? t("common.adjustTheDateAndFilters"),
                icon: emptyStateProps?.icon,
            }}
            {...props}
        />
    );
};
