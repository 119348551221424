import { sum } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { addLineDirectionToData, GraphDataItem } from "src/helpers/graphs";
import { CategoryLevel1 } from "src/models/ReportCategoryModel";

import { useIncidentAndDeviationCategoriesCount, useSummaryPeriod } from "../shared/hooks";
import { CHART_LEGEND_CATEGORIES, LABEL_HEIGHT_INDEX } from "./IncidentDistributionWidget.constants";

const parseToPieChartData = (data: Partial<Record<CategoryLevel1, number>>, total: number, onItemClick?: (categotyKey: string) => void): GraphDataItem[] => {
    if (!total) {
        return [];
    }

    return CHART_LEGEND_CATEGORIES.map((category) => {
        const dataValue = data[category.key] || 0;

        return {
            ...category,
            value: parseFloat(((dataValue / total) * 100).toFixed(1)),
            name: category.key,
            ...(onItemClick && {
                onItemClick: () => onItemClick(category.key),
            }),
        };
    });
};

export const useIncidentDistributionWidget = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { isLoading: isLoadingCurrent, queryResult: currentValues } = useIncidentAndDeviationCategoriesCount(currentPeriod.start, currentPeriod.end);
    const { isLoading: isLoadingPrevious, queryResult: oldValues } = useIncidentAndDeviationCategoriesCount(previousPeriod.start, previousPeriod.end);

    const incidentCount = useMemo(() => {
        return {
            previousValue: oldValues ? sum(Object.values(oldValues)) : 0,
            value: currentValues ? sum(Object.values(currentValues)) : 0,
        };
    }, [oldValues, currentValues]);

    const chartData = useMemo(() => {
        const handleItemClick = (categoryKey: string) => {
            navigate(`/insights/overview-events/${categoryKey}`, {
                state: {
                    startDate: currentPeriod.start,
                    endDate: currentPeriod.end,
                    originUrl: "/insights",
                    originTitle: t("insights.tabs.summary"),
                    originActiveTab: 0,
                },
            });
        };

        return parseToPieChartData(currentValues, incidentCount.value, handleItemClick);
    }, [currentValues, incidentCount.value, navigate, currentPeriod.start, currentPeriod.end, t]);

    const chartDataWithLineDiection = useMemo(() => {
        return addLineDirectionToData(
            chartData.filter((d) => d.value).map((d) => ({ ...d, visualValue: d.value })),
            LABEL_HEIGHT_INDEX,
        );
    }, [chartData]);

    return {
        incidentCount,
        chartData: chartDataWithLineDiection,
        isLoading: isLoadingCurrent || isLoadingPrevious,
    };
};
