import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useResizeObserver } from "src/hooks/CommonHooks";
import ReportsSelectors from "src/store/reports/ReportsSelectors";

import Generic from "../../Generic";
import { SingleReportView, Wrapper } from "./ReportDisplayModal.styles";

export const ReportDisplayModal = ({ isOpen, report, siteObject, onClose, isReportListDetails }) => {
    const reportDetailsContainer = document.getElementById("report-list-details");
    const asideNavbarPanel = document.getElementById("aside-navbar-panel");

    const [reportDetailsContainerWidth] = useResizeObserver(reportDetailsContainer);
    const [navbarWidth] = useResizeObserver(asideNavbarPanel);

    const isSingleReportPage = useSelector(ReportsSelectors.getIsSingleReportPage);

    if (!isOpen) return null;

    const content = <Generic report={report} siteObject={siteObject} onClose={onClose} isReportListDetails={isReportListDetails} showBackButton={true} />;

    return createPortal(
        <Wrapper width={reportDetailsContainerWidth} navbarWidth={navbarWidth} isSingleReport={!isReportListDetails}>
            {report && isSingleReportPage ? <SingleReportView>{content}</SingleReportView> : content}
        </Wrapper>,
        document.body,
    );
};
